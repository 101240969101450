import React from "react";
import styles from "./about.module.css";

const ValueCard = ({ item }) => {
  return (
    <div className={styles.valuecardContainer}>
      <div className={styles.upperCard}>
        <p className={styles.cardheading}>{item.id}.</p>
        <p className={styles.cardheading}>{item.value}</p>
        <img
          src={item.icon}
          width={70}
          height={70}
          alt={item.value}
          className={styles.valueimages}
        />
      </div>
      <div className={styles.lowerCard}>
        <p
          className="desc_texts"
          style={{ color: "#424242", fontWeight: "400", textAlign: "initial" }}
        >
          {item.desc}
        </p>
      </div>
    </div>
  );
};

export default ValueCard;
