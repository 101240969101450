import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as authApi from "../api/auth";
// export const gatrack = (event, params = {}) => {
//   window.dataLayer = window.dataLayer || [];
//   params.event = event;
//   window.dataLayer.push(params);
// };
const initialState = {
  isAuthenticated: false,
  mobileOtpSend: false,
  verifyOtp: false,
  usersData: [],
  user: null,
  loading: false,
  successMsg: null,
  notificationData: [],
  error: null,
  success: true,
  mobileNotifyError: null,
  isPasswordChange: false,
  otpSendSuccessMsg: null,
  authUser: [],
  responseVerifyMob: true,
  totalAttempts: null,
  errorMsg: null,
  showProfile: false,
  loginMessage: "",
};


// export const VerifyEmail = createAsyncThunk(
//   "auth/verifyEmail",
//   async (formData, { rejectWithValue }) => {
//     console.log(formData, "formdata from authslice signup");
//     try {
//       const resp = await authApi.verifyEmail(formData);
//       // console.log(resp.data, "resp");
//       if (resp.data.code == 200) {
//         // cbFunc();
//         return resp.data.message;
//       } else {
//         console.log("not working !!");
//         return rejectWithValue(resp.data.message);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );

//====================================================google flow =================================================/////

// export const NotificationForUser = createAsyncThunk(
//   "auth/notificationforuser",
//   async (_, { rejectWithValue }) => {
//     // console.log(formData, "formdata from authslice google");
//     try {
//       const resp = await authApi.notificationForUser();
//       // console.log(resp.data, "resp");
//       if (resp.data.code == 200) {
//         // cbFunc();
//         return resp.data.data;
//       } else {
//         console.log("not working !!");
//         return rejectWithValue(resp.data.message);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );



export const GetUserProfileData = createAsyncThunk(
  "auth/profile",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.getUserProfile();
      if (resp.data.code == 200) {
        console.log(resp.data, "resp");
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);




export const ChangePassword = createAsyncThunk(
  "auth/changepassword",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.changePassword(formData);
      if (resp.data.code == 200) {
        console.log(resp.data, "resp");
        return resp.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const UpdateUserProfile = createAsyncThunk(
  "auth/updateProfile",
  async (formData, { rejectWithValue }) => {
    console.log(formData, "in UpdateUserProfileSlice");
    try {
      const resp = await authApi.updateUserProfile(formData);
      if (resp.data.code == 200) {
        console.log(resp.data, "resp");
        return resp.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// export const sendSubMail = createAsyncThunk(
//   "/auth/subscribeemail",
//   async (data, { rejectWithValue }) => {
//     try {
//       const resp = await authApi.SendSubEmail(data);
//       if (resp.data.code == 200) {
//         message.success(
//           resp?.data?.message ? resp?.data?.message : "Email is subScribed"
//         );
//         return resp.data.message;
//       } else {
//         message.success(
//           resp?.data?.message ? resp?.data?.message : "Email is not subscribed"
//         );
//         return rejectWithValue(resp.data.message);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    sendLoginMessage: (state, action) => {
      state.loginMessage = action.payload;
    },
    setUser: (state, action) => {
      // console.log(state, action.payload, "from setuser");
      state.user = action.payload;
    },
    setUserAuthenticated: (state, action) => {
      state.isAuthenticated = true;
      state.usersData = action.payload;
    },
  },

  extraReducers: (builder) => {
 



  

    ////==================verify email=================///////////////

    // builder.addCase(VerifyEmail.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(VerifyEmail.fulfilled, (state, action) => {
    //   // state.isAuthenticated = true;
    //   state.successMsg = action.payload;
    // });
    // builder.addCase(VerifyEmail.rejected, (state, action) => {
    //   // state.isAuthenticated = false;
    //   state.errorMsg = action.payload;
    // });

    //=====================================NotificationForUser flow =================================================/////

    // builder.addCase(NotificationForUser.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(NotificationForUser.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.notificationData = action.payload;
    //   // state.isAuthenticated = true;
    //   // state.usersData=action.paylo
    // });
    // builder.addCase(NotificationForUser.rejected, (state, action) => {
    //   state.loading = false;
    //   state.errorMsg = action.payload;
    // });

 






    //=====================================userProfile process===============================//

    ////==================Get USer Profile data=================

    builder.addCase(GetUserProfileData.pending, (state) => {
      state.loading = true;
      state.isAuthenticated = false;
      state.usersData = null;
    });
    builder.addCase(GetUserProfileData.fulfilled, (state, action) => {
      console.log("usersdata", action.payload);

      state.isAuthenticated = true;
      state.usersData = action.payload;
    });
    builder.addCase(GetUserProfileData.rejected, (state, action) => {
      state.isAuthenticated = false;

      state.usersData = null;
      state.errorMsg = action.payload;
    });

    ////==================update  USer Profile data=================

    builder.addCase(UpdateUserProfile.pending, (state, action) => {
      state.loading = true;
      state.isAuthenticated = true;
      state.usersData = action.payload;
    });
    builder.addCase(UpdateUserProfile.fulfilled, (state, action) => {
      console.log("usersdata", action.payload);

      message.success("User Updated Successfully");
      state.loading = true;
      state.isAuthenticated = false;
      state.usersData = null;
    });
    builder.addCase(UpdateUserProfile.rejected, (state, action) => {
      state.loading = false;
      // message.error(action.payload);
      state.isAuthenticated = false;
      state.usersData = null;
    });




    ///changePaword====================

    builder.addCase(ChangePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ChangePassword.fulfilled, (state, action) => {
      // console.log("usersdata", action.payload);
      state.successMsg = action.payload.message;
    });
    builder.addCase(ChangePassword.rejected, (state, action) => {
      // console.log(action.payload, "payload")
      // state.mobileOtpSend = false;
      state.errorMsg = action.payload;
    });

    // ///================sendSubMail===============================
    // builder.addCase(sendSubMail.pending, (state,action) => {
    //   state.loading = true;
    // });
    // builder.addCase(sendSubMail.fulfilled, (state,action) => {
    //   state.loading = false;
    // });
    // builder.addCase(sendSubMail.rejected, (state,action) => {
    //   state.error = action.payload;
    // });
  },
});

export const { setUser, setUserAuthenticated, sendLoginMessage } =
  authSlice.actions;
export default authSlice.reducer;
