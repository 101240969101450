import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { Box, Stack } from "@mui/material";
import styles from "../../commonStyling/common.module.css";
import { sub } from "date-fns";
import { Link } from "react-router-dom";

function SubscriprionCards({
  subItem,
  handleOpen,
  open,
  setOpen,
  handleClose,
  handleSubsDetails,
  setNewSubsData,
  newSubsData,
  handleSubmitSubscriptionDetails,
}) {
  const params = window.location.pathname;
  const handleModalOpen = (sub_id, subtitle) => { };
  // console.log(subItem, "subItem");
  console.log(subItem, subItem?.sub_logo, "subItem?.sub_logo")
  return (
    <Link
      to={{
        pathname: `/dashboard/details/${subItem?.sub_name
          ?.split(" ")
          .join("")}/${subItem?.sub_id}`,
      }}
    >
      {/* <button onClick={() => handleModalOpen(subItem.sub_id)}> */}
      <Card
        sx={{
          maxWidth: 200,
          display: "flex",
          flexDirection: "column",
          paddingTop: "24px",
          borderRadius: "16px",
          background: "#FFF",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardMedia
          sx={{ display: "flex", justifyContent: "center", flex: "1 1 100%" }}
        >
          <img
            src={subItem?.sub_logo}
            width={80}
            height={80}
            alt="subscription"
          />
        </CardMedia>
        <CardContent
          sx={{ padding: "24px 16px", flex: "1 1 100%", gap: "8px" }}
        >
          {subItem?.pay_date && (
            <Stack direction="row" spacing={2} marginBottom={"5px"}>
              <p className={styles.para_bold}>
                Pay date:
                <span className={styles.sm_text}> {subItem.pay_date} </span>
              </p>
            </Stack>
          )}
          {(subItem?.days || subItem?.days === 0) && (
            <Stack direction="row" spacing={2}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderRadius={"4px"}
                padding={"5px"}
                sx={{ background: "rgba(153, 153, 153, 0.19)" }}
                width={"100%"}
              >
                <p className={styles.sm_text}>{subItem?.days} Days remaining</p>
                <img
                  src={"/assets/timer.svg"}
                  alt="timer"
                  width={20}
                  height={20}
                />
              </Box>
            </Stack>
          )}

          {subItem?.type == "paid" || subItem.cost !== null ? (
            <Stack direction="row" mt={1}>
              <p
                className={styles.para_18_bold}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                ${subItem?.cost}
                <span className={styles.para_14} style={{ display: "inline" }}>
                  {subItem?.plan_name
                    ? `/ ${subItem?.plan_name?.slice(0, 2)}`
                    : " to be paid"}
                </span>
              </p>
            </Stack>
          ) : (
            <div style={{ position: "relative" }}>
              <img
                style={{ marginTop: "5px" }}
                src="/assets/yellowribbon.svg"
              />
              <p
                // className={styles.para_14_bold}
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "0",
                  fontSize: "15px",
                }}
              >
                Free
              </p>
            </div>
          )}

          {subItem?.sub_description && (
            <Stack direction="row" mt={1}>
              <p className={styles.sub_description}>
                {subItem.sub_description}
              </p>
            </Stack>
          )}
        </CardContent>
        <CardActions
          className={styles.card_actions_btn}
          sx={{
            background:
              "linear-gradient(0deg, #D0EE11 0%, #D0EE11 100%), #A7BF0E",
            flex: "1",
          }}
        >
          <p className={styles.para_14}>
            {" "}
            {params.includes("add-new")
              ? "Add"
              : params.includes("subscription-list")
                ? "Manage"
                : "Access"}{" "}
            {subItem?.sub_name}
          </p>
        </CardActions>
      </Card>
      {/* </button> */}
    </Link>
  );
}

export default SubscriprionCards;
