import React from "react";
import styles from "./explore.module.css";
import { Grid, Stack } from "@mui/material";


const extraFeatures = [
  {
    id: 0,
    featureTitle: "Renew your Subscriptions Directly ",
    featureDesc:
      "Access your complete credit report and history. Get alerted to important changes that impact your score.",
    icon: "/assets/speedometer.png",
  },
  {
    id: 1,
    featureTitle: "Track your cost on OTT Platforms",
    featureDesc:
      "Our concierge will identify bills that can be lowered and negotiate on your behalf for the best rates available.",
    icon: "/assets/speedometer.png",
  },
  {
    id: 2,
    featureTitle: "Know the Bill Dates of Your Subscriptions",
    featureDesc:
      "Our concierge will identify bills that can be lowered and negotiate on your behalf for the best rates available.",
    icon: "/assets/speedometer.png",
  },
  {
    id: 3,
    featureTitle: "Check Your activity on Various Platforms",
    featureDesc:
      "Our concierge will identify bills that can be lowered and negotiate on your behalf for the best rates available.",
    icon: "/assets/speedometer.png",
  },
];

const ExtraFeatures = () => {
  return (
    <div className={`wholeWidthContainer marg_100 ${styles.container}`}>
      <div className="pageWrapper">
        <div className={styles.extrafeaturecontainer}>
          <h2
            className="header_2"
            style={{ color: "#030303", fontWeight: "600" }}
          >
            Alladdin Can Also Help You...
          </h2>

          <div className={styles.featurescontainer}>
            <Grid className={styles.featureWrapper}>
              {extraFeatures.map((feature, id) => (
                <div className={styles.singleFeature} key={id}>
                  <div className={styles.featureiconcontainer}>
                    <img
                      src={feature.icon}
                      width={40}
                      height={40}
                      alt={feature.featureTitle}
                    />
                  </div>
                  <h3 className={styles.featuretitle}>
                    {feature.featureTitle}
                  </h3>
                  <p
                    className="desc_texts"
                    style={{ fontWeight: "400", textAlign: "center" }}
                  >
                    {feature.featureDesc}
                  </p>
                </div>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraFeatures;
