import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/authSlice";
import subscriptionReducer from "./features/subscriptionSlice";

const store = configureStore({
  reducer: {
    userAuth: authReducer,
    subscription: subscriptionReducer,
  },
});

export default store;
