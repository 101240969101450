import React from "react";
import styles from "./features.module.css";

import FeatureCard from "./FeatureCard";
import ParentHeroComponent from "../commonComponents/ParentHeroComponent";
import commonStyles from "../commonComponents/common.module.css";

const HeroSection = () => {
  const featuresPlan = [
    {
      id: 0,
      planTitle: "Free / Standard (7Days)",
      isSaving: false,
      fees: false,
      savings: "",
      costs: 0,
      planFeatures: [
        {
          feature: "Basic payment tracking",
        },
        {
          feature: "Projected spending",
        },
        {
          feature: "Subscription overview",
        },
        {
          feature: "Free trial tracking",
        },
        {
          feature: "Alerts and Notifications",
        },
      ],
      buttonText: "Sign in",
    },
    {
      id: 1,
      planTitle: "Premium",
      isSaving: true,
      savings: "20%",
      fees: true,
      planprevfeesMonthly: "$7.99/ m",
      planfeesMonthly: "$4.99/ m",
      costs: "4.99",
      annualbill: "$70 billed annually",
      planFeatures: [
        {
          feature: "All Standard features",
        },
        {
          feature: "Recommended services",
        },
        {
          feature: "Activity Stream",
        },
        {
          feature: "Universal Pause & Resume button",
        },
        {
          feature: "Calendar view",
        },
        {
          feature: "Priority Support",
        },
      ],
      buttonText: "Get Started",
    },
  ];
  return (
    <ParentHeroComponent>
      <div className="pageWrapper">
        <div className={commonStyles.iconcontainer}>
          <img
            src="/assets/alladinicongray.svg"
            width={150}
            height={140}
            alt="alldin icon"
          />
        </div>
        <div className={styles.heroContainer}>
          <h1 className={styles.pricing_head}>Our Pricing</h1>
          <div className={styles.cardsContainer}>
            {featuresPlan.map((features, id) => (
              <FeatureCard key={id} feature={features} />
            ))}
          </div>

          {/* <h1
            className="header_2"
            style={{ textAlign: "center", fontWeight: "700" }}
          >
            Manage all OTT subscription at one place
          </h1> */}
        </div>
      </div>
    </ParentHeroComponent>
  );
};

export default HeroSection;
