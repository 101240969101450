export default {
  id: 1,
  planTitle: "Premium",
  isSaving: true,
  savings: "20%",
  fees: true,
  planprevfeesMonthly: "$7.99/ m",
  planfeesMonthly: "$4.99/ m",
  costs: "4.99",
  annualbill: "$70 billed annually",
  planFeatures: [
    {
      feature: "All Standard features",
    },
    {
      feature: "Recommended services",
    },
    {
      feature: "Activity Stream",
    },
    {
      feature: "Universal Pause & Resume button",
    },
    {
      feature: "Calendar view",
    },
    {
      feature: "Priority Support",
    },
  ],
  buttonText: "Go premium",
};
