import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";

import styles from "./preloginnavbar.module.css";

import AppConstants from "../../constants/AppConstants";
import { Link } from "react-router-dom";
// import FeedBackModal from "../Common/CommonComponents/FeedBackModal";

const PreLoginNavbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const [scrollY, setscrollY] = useState(0);

  // useEffect(() => {
  //   if (window) {
  //     // Check if window is defined before using it
  //     function updateScrollY() {
  //       setscrollY(window.scrollY);
  //     }

  //     window.addEventListener("scroll", updateScrollY);
  //     updateScrollY();

  //     return () => {
  //       window.removeEventListener("scroll", updateScrollY);
  //     };
  //   }
  // }, [scrollY]);
  const [scrolling, setScrolling] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  let closeTimeout = useRef(null);

  const handlePopoverOpen = (event) => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    closeTimeout.current = setTimeout(() => {
      setAnchorEl(null);
    }, 100);
  };

  const handleListItemClick = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    // Check if window is available (client-side)
    // console.log(window, "window");
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        if (window.scrollY > 20) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrolling]);

  // console.log(scrolling, "scrollinng");

  const drawerWidth = 375;
  const navItemsWithUrl = [
    { name: "Home", url: "/" },
    { name: "Features", url: "/features" },
    { name: "About us", url: "/about-us" },
    { name: "Contact us", url: "/contact-us" },
  ];

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className={styles.drawer_style}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <div
          className={styles.logocontainer}
          style={{ alignItems: "center", display: "flex" }}
        >
          <Link to={"/"}>
            <img
              src="/assets/alladdin_logo.svg"
              className="img"
              alt="logo"
              sizes="100vw"
            />
          </Link>
        </div>

        <div onClick={handleDrawerToggle}>
          <CloseIcon style={{ fontSize: 30 }} />
        </div>
      </div>
      <List>
        {navItemsWithUrl.map((item, index) => (
          <ListItem key={index} disablePadding>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                fontSize: "1.8rem",
                fontWeight: "700",
              }}
            >
              <ListItemButton>
                <ListItemText
                  className={styles.muiListText}
                  primary={<Link to={item.url}>{item.name}</Link>}
                />
              </ListItemButton>
            </div>
          </ListItem>
        ))}
        <ListItem>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
              fontSize: "1.8rem",
              fontWeight: "700",
            }}
          >
            {/* <ListItemButton> */}
            <ListItemText
              className={styles.muiListText}
              primary={
                <Link to={"/login"} target="_blank">
                  <button
                    type="button"
                    className={"cta_btn"}
                    style={{ width: "100%" }}
                  >
                    Sign In
                  </button>
                </Link>
              }
            />
            {/* </ListItemButton> */}
          </div>
        </ListItem>
        <ListItem>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              fontSize: "1.8rem",
              fontWeight: "700",
            }}
          >
            {/* <ListItemButton> */}
            <ListItemText
              className={styles.muiListText}
              primary={
                <Link to={"#"} target="_blank">
                  <button
                    type="button"
                    className={styles.nav_btn}
                    style={{
                      background: "#000",
                      width: "100%",
                      border: "none",
                    }}
                  >
                    Start Trial
                  </button>
                </Link>
              }
            />
            {/* </ListItemButton> */}
          </div>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          maxHeight: "80px",
          width: "100%",
          background: "#fff",
          borderBottom: "1px solid #B1C248",
        }}
      >
        <AppBar
          component="nav"
          style={{
            position: "fixed",
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: scrolling ? "blur(30px)" : "none",
            zIndex: 99,
          }}
          sx={{
            boxShadow: "none",
          }}
        >
          <div
            style={{
              maxWidth: "1440px",
              width: "100%",
            }}
          >
            <div className={styles.nav_main_div} style={{ width: "100%" }}>
              <div className={styles.nav_internal_div}>
                <Toolbar
                  className={styles.nav_toolbar_div}
                  style={{ justifyContent: "space-between" }}
                >
                  <div className={styles.logocontainer}>
                    <Link to={"/"}>
                      <img
                        src="/assets/alladdin_logo.svg"
                        className="img"
                        alt="logo"
                        sizes="100vw"
                      />
                    </Link>
                  </div>

                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        width: "100%",
                        alignItems: "center",
                      },
                    }}
                  >
                    <div className={styles.menueItems}>
                      {navItemsWithUrl?.map((item, index) => (
                        <Link
                          to={item?.url}
                          className={styles.anchor}
                          key={index}
                        >
                          {item?.name}
                        </Link>
                      ))}
                    </div>
                    <div className={styles.buttoncontainer}>
                      <Link to={"/login"}>
                        <button
                          type="button"
                          className={styles.login_btn}
                          style={{
                            color: "#030303",
                          }}
                        >
                          Sign In
                        </button>
                      </Link>
                      <Link to={"/signup"}>
                        <button
                          type="button"
                          className={styles.nav_btn}
                          style={{
                            background: "#000",

                            border: "none",
                          }}
                        >
                          Start Trial
                        </button>
                      </Link>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: { md: "none", sm: "flex", xs: "flex" },
                      alignItems: "center",
                    }}
                  >
                    {/* <div
                      className={styles.buttoncontainer}
                      style={{ paddingRight: "20px" }}
                    >
                      <a href={"#"} target="_blank">
                        <button
                          type="button"
                          className={styles.nav_btn}
                          style={{
                            background: "#7F56D9",

                            border: "1px solid #7F56D9",

                            color: "#fff",
                          }}
                        >
                          Sign Up
                        </button>
                      </a>
                    </div> */}

                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ display: { md: "none" } }}
                      className={styles.hamburger_menu}
                    >
                      <MenuIcon style={{ color: "black" }} />
                    </IconButton>
                  </Box>
                </Toolbar>
              </div>
            </div>
          </div>
        </AppBar>
        <nav>
          <Drawer
            container={
              typeof window !== "undefined"
                ? () => window.document.body
                : undefined
            }
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </>
  );
};

export default PreLoginNavbar;
