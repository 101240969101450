import { string, number, object, ref } from "yup";

export const SignupSchema = object().shape({
  name: string().required("Name is required"),
  email: string()
    .email()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Please enter a valid email")
    .required("Email is required"),
  password: string()
    .min(8, "password must be atleast 8 characters long")
    .required("Password is required"),
  conform_password: string()
    .min(8, "password must be atleast 8 characters long")
    .oneOf([ref("password"), null], "Passwords don't match")
    .required("Password is required"),
  // password2: string()
  //   .oneOf([ref("password"), null], "Passwords don't match")
  //   .required("Confirm Password is required")
});



export const EmailLoginSchema = object().shape({
  password: string()
    .min(8, "password must be atleast 8 characters long")
    .required("Password is required"),
  email: string()
    .email()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Please enter a valid Phone No.")
    .required("Email is required"),
});



export const SocialMobSchema = object().shape({
  password: string()
    .min(8, "password must be atleast 8 characters long")
    .required("Password is required"),
  mobile: string()
    .matches(/[6-9][0-9]{9}/, "Please enter a valid Phone No.")
    .min(10)
    .max(10)
    .required("Phone No. is required"),
});

export const Password = object().shape({
  new_password: string()
    .min(8, "password must be atleast 8 characters long")
    .required("Password is required"),
  conform_password: string().oneOf(
    [ref("new_password"), null],
    "Passwords must match"
  ),
  //.matches('new_password','new password and confirm password are not same')
});
