import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "./sub.module.css";
import { useParams } from "react-router-dom";

import DiscriptionSec from "./DiscriptionSec";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSubscription,
  GetSubsDetails,
  Getsubsgraphs,
} from "../../../redux/features/subscriptionSlice";
import AreaGraphComponent from "./AreaGraphComponent";
import { Select, message } from "antd";
import ModalComponent from "../../addnew/ModalComponent";
import { useAuth } from "../../../context/authContext";

const Subscription = ({}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const [days, setDays] = useState(30);
  const [graphSeries, setGraphSeries] = useState([]);
  const [graphOption, setgraphOption] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const perticularSubsData = useSelector(
    (state) => state.subscription.perticularSubsData
  );
  const perticularSubsCostData = useSelector(
    (state) => state.subscription.perticularSubsCostData
  );

  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "standard",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
  });

  const handleSubsDetails = (e) => {
    // console.log(e.target.value, "e.target.value");
    setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
  };
  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();

    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }
    // console.log(newSubsData, "newSubsData");
    dispatch(AddSubscription(newSubsData))
      .then((res) => {
        message.success("Subscription Added Successfully");
        // console.log(res, "res of add new");
        // dispatch(GetALLSubscriptions());r
      })
      .catch((err) => message.error(err));
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "standard",
      sub_type: "paid",
      free_trial_days: "",
      sub_id: null,
    });
    handleClose();
  };
  
  const handleChange = (value) => {
    // console.log(value.value,"value"); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    setDays(value.value);
  };

  useEffect(() => {
    dispatch(Getsubsgraphs({ sub_id: params?.sub_id, days: days }))
      .then((res) => {
        setGraphSeries(res.payload.map((graph) => graph.graph_data.price));
        setgraphOption(res.payload.map((graph) => graph.graph_data.time));
      })
      .catch((err) => console.log(err));
  }, [days]);

  const perticularGraphData = useSelector(
    (state) => state.subscription.perticularGraphData
  );

  useEffect(() => {
    dispatch(
      GetSubsDetails({ sub_name: params?.sub_name, sub_own_id: params?.id })
    );
    dispatch(Getsubsgraphs({ sub_name: params?.sub_name, days: days }))
      .then((res) => {
        setGraphSeries(res.payload.map((graph) => graph.graph_data.price));
        setgraphOption(res.payload.map((graph) => graph.graph_data.time));
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(perticularSubsData[0], "perticularSubsData[0]");
  // console.log(newSubsData, "newSubsData");

  const breadcrumbs = [
    <Link
      underline="hover"
      color="inherit"
      to="/"
      //   onClick={handleClick}
    >
      <p className={styles.breadcrumText}> App </p>
    </Link>,

    <p className={styles.breadcrumText}>{params?.sub_name}</p>,
  ];
  return (
    <>
      {perticularSubsData && (
        <div className="mainSubscriptionContainer">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>

          <div className={styles.headerContainer}>
            <div className={styles.appImageContainer}>
              <img src={perticularSubsData[0]?.sub_logo} className="image" />
            </div>
            <div className={styles.appAboutContainer}>
              <h2 className="postloginHeading">
                {/* {perticularSubsData[0]?.sub_name} */}
                {params.sub_name}
              </h2>
              {perticularSubsCostData.length > 0 && (
                <p
                  className="underlinetext"
                  style={{
                    textDecoration: "none",
                    color: "#030303",
                    fontWeight: "700",
                  }}
                >
                  Pay date:{" "}
                  <span
                    className="underlinetext"
                    style={{
                      textDecoration: "none",
                      color: "#030303",
                      fontWeight: "400",
                    }}
                  >
                    {/* 6th July 2023 */}
                    {perticularSubsCostData[0]?.pay_date}
                  </span>
                </p>
              )}
              {perticularSubsCostData.length > 0 && (
                <div className={styles.reminderdiv}>
                  <p
                    className="underlinetext"
                    style={{
                      textDecoration: "none",
                      color: "#030303",
                      fontWeight: "400",
                    }}
                  >
                    {` ${perticularSubsCostData[0]?.days} Days remaining`}
                  </p>
                  <img src="/assets/timeicon.svg" width={20} height={20} />
                </div>
              )}

              <p className="dark_desc_texts" style={{ fontWeight: "700" }}>
                ${perticularSubsData[0]?.cost}{" "}
                <span
                  className="underlinetext"
                  style={{
                    textDecoration: "none",
                    color: "#030303",
                    fontWeight: "400",
                  }}
                >
                  to be paid
                </span>
              </p>

              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonType_2}
                  onClick={() => {
                    setNewSubsData({
                      ...newSubsData,
                      sub_id: perticularSubsData[0].sub_id,
                      sub_title: perticularSubsData[0]?.sub_name,
                    });
                    handleOpen();
                  }}
                >
                  <>
                    Manage Subscription{" "}
                    <img
                      src={"/assets/pencilicon.svg"}
                      width={15}
                      height={15}
                    />
                  </>
                </button>
              </div>
            </div>
          </div>

          <DiscriptionSec
            sub_description={perticularSubsData[0]?.sub_description}
          />
          <div className="buttonsContainer flex justify-end my-3">
            <Select
              className="select-class"
              style={{ border: "none !important" }}
              labelInValue
              defaultValue={{
                value: 30,
                label: (
                  <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                    30 days
                  </p>
                ),
              }}
              onChange={handleChange}
              options={[
                {
                  value: 60,
                  label: (
                    <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                      60 days
                    </p>
                  ),
                },
                {
                  value: 30,
                  label: (
                    <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                      30 days
                    </p>
                  ),
                },
                {
                  value: 180,
                  label: (
                    <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                      6 Months
                    </p>
                  ),
                },
              ]}
            />
          </div>
          {perticularGraphData.length > 0 ? (
            <AreaGraphComponent
              graphData={perticularGraphData[0]}
              graphSeries={graphSeries}
              graphOption={graphOption}
            />
          ) : (
            <p className="header_1">No Data for Graph</p>
          )}

          <ModalComponent
            open={open}
            setOpen={setOpen}
            userData={userData}
            handleClose={handleClose}
            handleSubsDetails={handleSubsDetails}
            setNewSubsData={setNewSubsData}
            newSubsData={newSubsData}
            handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          />
        </div>
      )}
    </>
  );
};

export default Subscription;
