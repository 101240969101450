import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function SuccessPage() {
  const [timer, setTimer] = useState(5);
  const navigate = useNavigate(); //

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      navigate("/dashboard");
      clearInterval(countdown);
      // navigate('/') // Redirect to the home page
    }

    return () => clearInterval(countdown);
  }, [timer]);

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "48px", marginTop: "20px" }}>Thank You!!!</h1>
      <div
        className="tick-animation"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/assets/success.gif"}
          alt="Green Tick"
          style={{ width: "50%", height: "80%" }}
        />
      </div>

      <h1 style={{ fontSize: "24px", marginTop: "20px" }}>
        Payment Successful
      </h1>
      <p style={{ fontSize: "18px", marginTop: "20px" }}>
        Redirecting in {timer} seconds...
      </p>
    </div>
  );
}

export default SuccessPage;
