import React, { useEffect, useState } from "react";
import styles from "./notification.module.css";
import trstyles from "../Transactions/transaction.module.css";
import { Select } from "antd";
import NotificationBody from "./NotificationBody";
import { showNotifications } from "../../../redux/features/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

function Notifications() {
  const dispatch = useDispatch();
  const [searchedNotifications, setSearchedNotifications] = useState([]);
  const [serchedText, setserchedText] = useState("");
  // const [days, setDays] = useState("");
  const { notifications } = useSelector((state) => ({
    ...state.subscription,
  }));
  const handleChange = (value) => {
    // console.log(value.value);
    let data = {};
    data.days = value.value;
    dispatch(showNotifications(data));
    // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };
  const handleSearch = (e) => {
    setserchedText(e.target.value);
    if (e.target.value !== "") {
      let filteredNotifications = notifications.filter((notification) =>
        notification.title.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSearchedNotifications(filteredNotifications);
    } else {
      setSearchedNotifications(notifications);
    }
  };

  useEffect(() => {
    let data = {};
    data.days = 30;
    dispatch(showNotifications(data));
  }, []);
  return (
    <>
      <h3
        className="postloginHeading"
        style={{ marginBottom: "8px", fontWeight: "500" }}
      >
        Notification
      </h3>

      <div className={styles.header}>
        <div className={trstyles.searchContainer}>
          <input
            type="text"
            className={trstyles.searchfeild}
            placeholder="search..."
            onChange={handleSearch}
            value={serchedText}
          />
          <img
            src="/assets/searchicon.svg"
            alt="searchicon"
            style={{ width: "16px", height: "16px" }}
          />
        </div>
        <Select
          className="select-class"
          style={{ border: "1px solid rgba(136, 136, 136, 0.4)" }}
          labelInValue
          defaultValue={{
            value: 30,
            label: (
              <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                30 days
              </p>
            ),
          }}
          onChange={handleChange}
          options={[
            {
              value: 60,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  60 days
                </p>
              ),
            },
            {
              value: 30,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  30 days
                </p>
              ),
            },
          ]}
        />
      </div>

      <NotificationBody
        notifications={
          searchedNotifications.length > 0
            ? searchedNotifications
            : notifications
        }
      />
    </>
  );
}

export default Notifications;
