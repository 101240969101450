import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AreaGraphComponent = ({ graphData }) => {
  // console.log(graphData, "graphData");
  const { graph_data } = graphData;
  // console.log(graph_data, "graph_data");
  if (!graph_data || graph_data.length === 0) {
    // Handle the case when data is missing or empty
    return <div>No data available for the chart.</div>;
  }

  // const [areaChart, setAreaChart] = useState({
  //   series: [
  //     {
  //       data: graph_data && graph_data?.map((graph) => graph.price),
  //       stroke: "#E50913", // Line color
  //       fill: "#E50913",
  //       strokeWidth: 0,
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "area",
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: "smooth",
  //     },
  //     xaxis: {
  //       // type: "datetime",
  //       categories: graph_data && graph_data?.map((graph) => graph.time),
  //     },
  //     theme: {
  //       palette: "custom", // Set palette to "custom"
  //       monochrome: {
  //         enabled: true,
  //         color: "#E50913", // Set the color for the theme
  //         shadeTo: "light",
  //         shadeIntensity: 0.2,
  //       },
  //     },
  //     tooltip: {},
  //   },
  // });

  const series = [
    {
      data: graph_data && graph_data?.map((graph) => graph.price),
      stroke: "#E50913", // Line color
      fill: "#E50913",
      strokeWidth: 0,
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      // type: "datetime",
      categories: graph_data && graph_data?.map((graph) => graph.time),
    },
    theme: {
      palette: "custom", // Set palette to "custom"
      monochrome: {
        enabled: true,
        color: "#E50913", // Set the color for the theme
        shadeTo: "light",
        shadeIntensity: 0.2,
      },
    },
    tooltip: {},
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default AreaGraphComponent;
