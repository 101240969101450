import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./transaction.module.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import DataGridComponent from "./DataGridComponent";
import {
  allTransactionsData,
  filterSearch,
} from "../../../redux/features/subscriptionSlice";

function Transactions() {
  const dispatch = useDispatch();
  const currentDate = dayjs();
  const sevenDaysAgo = dayjs().subtract(30, "day");
  const [value, setValue] = React.useState([sevenDaysAgo, currentDate]);
  const [minEndDate, setMinEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [searchedData, setSearchedData] = useState([]);

  const { allUserTransactionsData } = useSelector(
    (state) => state.subscription
  );

  // console.log(filteredUserTransactionsData, "filteredUserTransactionsData");

  const handleDateChange = (newValue) => {
    setValue(newValue);
    const [startDate, endDate] = newValue;

    if (startDate && !endDate) {
      setMinDate(startDate);
      setMinEndDate(startDate.add(1, "day"));
    } else if (!startDate) {
      setMinDate(null); // reset if start date is cleared
      setMinEndDate(null);
    } else if (startDate && endDate) {
      setMinDate(startDate); // keep start date's min restriction after both dates are selected
      setMinEndDate(null);
    }
  };
  // console.log(allUserTransactionsData,"allUserTransactionsData")
  useEffect(() => {
    const [startDate, endDate] = value;
    let data = {};
    if (startDate) {
      // console.log("Selected Start Date:", startDate.format("YYYY-MM-DD"));
      data.start_date = startDate.format("YYYY-MM-DD");
    } else {
      console.log("Start Date not selected");
    }

    if (endDate) {
      // console.log("Selected End Date:", endDate.format("YYYY-MM-DD"));
      data.end_date = endDate.format("YYYY-MM-DD");
    } else {
      console.log("End Date not selected");
    }

    if (startDate && endDate) {
      console.log("Both dates selected");
      dispatch(allTransactionsData(data));
    }
  }, [value]);

  const handleSearch = (e) => {
    // console.log(e.target.value, "e.target.value");
    setsearchedText(e.target.value);
    // setTimeout(() => {
    //   console.log("in timeout");
    //   dispatch(filterSearch(e.target.value));
    // }, 3000);
    if (e.target.value !== "") {
      let filteredData = allUserTransactionsData.filter((data) =>
        data.sub_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSearchedData(filteredData);
    } else {
      setSearchedData(allUserTransactionsData);
    }

    // console.log(filteredData, "data");
    // setSearchedData()
  };

  return (
    <>
      <h3
        className="postloginHeading"
        style={{ marginBottom: "8px", fontWeight: "500" }}
      >
        Transaction History
      </h3>

      <div className={styles.filterContainer}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchfeild}
            placeholder="search..."
            onChange={handleSearch}
            value={searchedText}
          />
          <img
            src="/assets/searchicon.svg"
            alt="searchicon"
            style={{ width: "16px", height: "16px" }}
          />
        </div>
        <div className={styles.dateinputContainer}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            style={{ flex: "1" }}
          >
            <DemoContainer components={["DateRangePicker"]}>
              <DemoItem component="DateRangePicker">
                <DateRangePicker
                  value={value}
                  minDate={minDate} // if startDate is selected, set it as minDate
                  onChange={handleDateChange}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>

      <DataGridComponent
        allUserTransactionsData={
          searchedData.length > 0 ? searchedData : allUserTransactionsData
        }
      />
    </>
  );
}

export default Transactions;
