"use client";
import React from "react";
import styles from "./common.module.css";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";

const FeaturesSection = ({ feature }) => {
  console.log(feature, "feature");
  return (
    <div className="pageWrapper">
      <Stack
        direction={{
          xs: "column",
          sm: feature.id % 2 == 0 ? "row-reverse" : "row",
        }}
        flexWrap="wrap"
        alignItems={"center"}
        justifyContent={"space-around"}
        className={styles.featureWrapper}
      >
        <div
          className={
            feature?.border ? styles.leftPartWithBorder : styles.leftPart
          }
          style={{ alignItems: "stretch" }}
        >
          <h2
            className="header_2"
            style={{ color: "#030303", fontWeight: "600", flex: "1" }}
            dangerouslySetInnerHTML={{ __html: feature.title }}
          ></h2>
          {feature.desc && (
            <p
              className="desc_texts"
              style={{ flex: "1" }}
              dangerouslySetInnerHTML={{ __html: feature.desc }}
            ></p>
          )}

          {feature.smartFeatures ? (
            <>
              {feature.features.map((item, id) => (
                <div
                  className={`flex ${styles.smartfeaturesContainer}`}
                  key={id}
                >
                  <div className={styles.smarticoncontainer}>
                    <img
                      src={item.icon}
                      className="img"
                      alt={item.featuretitle}
                    />{" "}
                  </div>
                  <div className={styles.smartfeaturetextContainer}>
                    <p className={styles.smartFeatureTitle}>
                      {item.featuretitle}
                    </p>
                    <p className="desc_texts">{item.featuredesc}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
          {feature.button ? (
            <div className={styles.buttoncontainer} style={{ flex: "1" }}>
              <Link to={"/login"} target="_blank">
                <button className="navigation_button">Learn More</button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.rightPart}>
          <img className="img" src={feature.img} alt="featureimg" />
        </div>
      </Stack>
    </div>
  );
};

export default FeaturesSection;
