import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import styles from "../contactus/contact.module.css";
import substyles from "../subscription/sub.module.css";
import addStyles from "./addnew.module.css";
import { Select } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useAuth } from "../../context/authContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalComponent = ({
  open,
  userData,
  setOpen,
  handleClose,
  handleSubsDetails,
  setNewSubsData,
  newSubsData,
  handleSubmitSubscriptionDetails,
}) => {
  // console.log(open, "handleClose");
  const handleChange = (e) => {
    // console.log(e.target.value, "value");
    setNewSubsData({ ...newSubsData, plan_name: e.target.value });
    // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };
  const handlePaymentMethod = (e) => {
    // console.log(e.target.value, "paymentvalue");
    setNewSubsData({ ...newSubsData, pay_method: e.target.value });
  };
  const handlePlanSubTypeChange = (e) => {
    // console.log(e.target.value, "paymentvalue");
    setNewSubsData({ ...newSubsData, sub_type: e.target.value });
  };
  const handlePlanName = (e) => {
    // console.log(e.target.value, "paymentvalue");
    setNewSubsData({ ...newSubsData, plan_name: e.target.value });
  };
  const handlePlanVarity = (e) => {
    // console.log(e.target.value, "paymentvalue");
    setNewSubsData({ ...newSubsData, plan_variety: e.target.value });
  };
  const handleDateChange = (newValue) => {
    setNewSubsData({
      ...newSubsData,
      start_date: moment(newValue.$d).format("YYYY-MM-DD"),
    });
  };

  const [datevalue1, setDateValue1] = useState();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addnewmodal"
      keepMounted
    >
      <Box sx={style}>
        <h2
          className="desc_texts"
          style={{ textAlign: "center", color: "#030303" }}
        >
          Add Subscription Details
        </h2>
        <form onSubmit={handleSubmitSubscriptionDetails}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div
              className=""
              style={{ display: "flex", gap: "20px", marginTop: "35px" }}
            >
              <div className={styles.inputfieldContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="fullname">Full Name</label>
                  <input
                    name="name"
                    type="text"
                    onChange={handleSubsDetails}
                    value={newSubsData.name}
                    id="fullname"
                    className="inputcontrol"
                    placeholder="Enter Full Name"
                  />
                </div>
              </div>
              <div className={styles.inputfieldContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="email">Email</label>
                  <input
                    name="email"
                    type="email"
                    value={newSubsData.email}
                    onChange={handleSubsDetails}
                    id="email"
                    className="inputcontrol"
                    placeholder="Enter Email Address"
                  />
                </div>
              </div>
            </div>
            <div className={styles.formcontrol}>
              <label htmlFor="Subscription">Subscription Title</label>
              <input
                name="sub_title"
                type="text"
                value={newSubsData.sub_title}
                onChange={handleSubsDetails}
                id="Subscription"
                className="inputcontrol"
                placeholder="Add Subscription Name"
              />
            </div>
            {/* <div className={styles.formcontrol}>
              <label htmlFor="Description">Description</label>
              <input
                name="sub_description"
                type="text"
                onChange={handleSubsDetails}
                value={newSubsData.sub_description}
                id="Description"
                className="inputcontrol"
                placeholder="Add Description"
              />
            </div> */}
            {/* <div className={styles.formcontrol}>
              <label htmlFor="Url">Add Url</label>
              <input
                name="url"
                type="text"
                value={newSubsData.url}
                onChange={handleSubsDetails}
                id="Url"
                className="inputcontrol"
                placeholder="Paste the Url Here"
              />
            </div> */}

            <div className={addStyles.lowerContainer}>
              {/* <div className={addStyles.thirdWidthContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="Cost">Cost</label>
                  <input
                    name="cost"
                    value={newSubsData.cost}
                    type="number"
                    onChange={handleSubsDetails}
                    id="Cost"
                    className="inputcontrol"
                    placeholder="$0.00"
                  />
                </div>
              </div>
              <div className={addStyles.thirdWidthContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="Payment">Payment Cycle</label>
                 
                  <select
                    name="paymentmethod"
                    className="modal-select-class"
                    onChange={handleChange}
                    defaultValue={"30"}
                  >
                    <option value="30">
                      <p
                        className="text_very_sm"
                        style={{ marginBottom: "0px" }}
                      >
                        30 days
                      </p>
                    </option>
                    <option value="60">
                      <p
                        className="text_very_sm"
                        style={{ marginBottom: "0px" }}
                      >
                        60 days
                      </p>
                    </option>
                  </select>
                </div>
              </div> */}
              <div
                className=""
                style={{ display: "flex", gap: "20px", marginTop: "35px" }}
              >
                <div className={styles.inputfieldContainer}>
                  <div className={styles.formcontrol}>
                    <label htmlFor="Paymentmethod">Subscription Type</label>

                    <select
                      name="paymentmethod"
                      className="modal-select-class"
                      onChange={handlePlanSubTypeChange}
                      defaultValue={"paid"}
                    >
                      <option value="free">
                        <p
                          className="text_very_sm"
                          style={{ marginBottom: "0px" }}
                        >
                          Free
                        </p>
                      </option>
                      <option value="paid">
                        <p
                          className="text_very_sm"
                          style={{ marginBottom: "0px" }}
                        >
                          Paid
                        </p>
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputfieldContainer}>
                  <div className={styles.formcontrol}>
                    <label htmlFor="email">Start Date</label>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      style={{ flex: "1" }}
                    >
                      <DatePicker
                        style={{ flex: "1" }}
                        value={datevalue1}
                        onChange={(newValue) => handleDateChange(newValue)}
                        className="modaldatepicker"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              {/* <div className={addStyles.thirdWidthContainer}></div> */}
              {newSubsData.sub_type === "paid" && (
                <>
                  {" "}
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label
                        htmlFor="Paymentmethod"
                        style={{ fontSize: "12px" }}
                      >
                        Payment method
                      </label>

                      <select
                        required
                        name="paymentmethod"
                        className="modal-select-class"
                        onChange={handlePaymentMethod}
                        defaultValue={"card"}
                      >
                        <option value="card">
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            Card
                          </p>
                        </option>
                        <option value="paypal">
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            Paypal
                          </p>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="card">Enter card no.</label>
                      <input
                        // required={
                        //   newSubsData.pay_method == "Card" ? true : false
                        // }
                        name="card_no"
                        value={newSubsData.card_no}
                        type="text"
                        onChange={handleSubsDetails}
                        id="card"
                        maxLength="4"
                        className="inputcontrol"
                        placeholder="Last 4 Digits"
                      />
                    </div>
                  </div>
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="planname">Plan Name</label>
                      <select
                        name="plan_variety"
                        className="modal-select-class"
                        onChange={handlePlanVarity}
                        defaultValue={"standard"}
                      >
                        <option value="standard">
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            Standard
                          </p>
                        </option>
                        <option value="premium">
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            Premium
                          </p>
                        </option>
                        <option value="pro">
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            Pro
                          </p>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <div className={styles.inputfieldContainer}>
                      <div className={styles.formcontrol}>
                        <label htmlFor="planname">Plans</label>
                        <select
                          name="plans"
                          className="modal-select-class"
                          onChange={handlePlanName}
                          defaultValue={"monthly"}
                        >
                          <option value="monthly">
                            <p
                              className="text_very_sm"
                              style={{ marginBottom: "0px" }}
                            >
                              Monthly
                            </p>
                          </option>
                          <option value="yearly">
                            <p
                              className="text_very_sm"
                              style={{ marginBottom: "0px" }}
                            >
                              Yearly
                            </p>
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.inputfieldContainer}>
                      <div className={styles.formcontrol}>
                        <label htmlFor="plancost">Plan Cost</label>
                        <input
                          required
                          name="cost"
                          value={newSubsData.cost}
                          type="text"
                          onChange={handleSubsDetails}
                          id="plancost"
                          maxLength="4"
                          className="inputcontrol"
                          placeholder="Enter Price"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {(newSubsData.sub_type == "" ||
                newSubsData.sub_type == "free") && (
                <div className={styles.formcontrol} style={{ width: "100%" }}>
                  <label htmlFor="Freetrial">Free trial valid for:</label>
                  <input
                    name="free_trial_days"
                    type="number"
                    value={newSubsData.free_trial_days}
                    onChange={handleSubsDetails}
                    id="Freetrial"
                    className="inputcontrol"
                    placeholder="Free trial valid for"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button
              type="button"
              className={substyles.buttonType_1}
              onClick={() => {
                setNewSubsData({
                  name: userData?.name,
                  email: userData?.email,
                  sub_title: "",
                  sub_description: "",
                  cost: "",
                  plan_name: "monthly",
                  start_date: "",
                  pay_method: "card",
                  card_no: "",
                  plan_variety: "standard",
                  sub_type: "paid",
                  free_trial_days: "",
                  sub_id: null,
                });
                handleClose();
              }}
              style={{ background: "#f5f5f5", flex: "none" }}
            >
              Cancel
            </button>
            <button
              className={substyles.buttonType_1}
              style={{ flex: "none" }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalComponent;

{
  /* <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="cardname">Enter Name</label>
                      <input
                        name="card_name"
                        type="text"
                        value={newSubsData.card_name}
                        onChange={handleSubsDetails}
                        id="cardname"
                        className="inputcontrol"
                        placeholder="Name on card"
                      />
                    </div>
                  </div> */
}
