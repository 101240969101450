


import React, { useEffect, useState } from "react";

import PreLoginNavbar from "../preloginNavbar/PreLoginNavbar";
import Footer from "../Footer";



export default function Layout({ children }) {
    return (
      <>
 
        <PreLoginNavbar />
        {children}
        <Footer />
      </>
    );
}
