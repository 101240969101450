import React, { useEffect } from "react";
import styles from "./subscription.module.css";
import SubscriprionCards from "../../common/commonComponents/SubscriptionCards";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions } from "../../../redux/features/subscriptionSlice";
import { currentSubscriptionList } from "../../../redux/features/subscriptionSlice";

function SubscriptionList() {
  // let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const dispatch = useDispatch();
  const { currentSubsList } = useSelector((state) => state.subscription);
  console.log(currentSubsList,"currentSubsList");

  useEffect(() => {
    // dispatch(GetALLSubscriptions());
    dispatch(currentSubscriptionList());
  }, []);
  // console.log(currentSubsList, "currentSubsList");
  return (
    <>
      <h3
        className="postloginHeading"
        style={{ marginBottom: "8px", fontWeight: "500" }}
      >
        Subscriptions
      </h3>

      <div className={styles.subscriptionContainer}>
        {currentSubsList?.map((item, id) => (
          <div className={styles.cardWrapper} key={id}>
            <SubscriprionCards subItem={item} />
          </div>
        ))}
      </div>
    </>
  );
}

export default SubscriptionList;
