import React from "react";
import styles from "./sub.module.css";

const DiscriptionSec = ({ sub_description }) => {
  return (
    <div className={styles.discMainContainer}>
      <p className="postloginHeading">Description</p>
      <p className="text_sm" style={{ textAlign: "left" }}>
        {sub_description}
      </p>
    </div>
  );
};

export default DiscriptionSec;
