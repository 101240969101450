import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import styles from "./transaction.module.css";

const DataGridComponent = ({ allUserTransactionsData }) => {
  // console.log(allUserTransactionsData, "allUserTransactionsData");
  const totalCost = allUserTransactionsData
    .filter((item) => item.cost !== null)
    .reduce((sum, item) => sum + parseFloat(item.cost), 0);
  console.log(totalCost, "totalCost");

  const columns = [
    {
      field: "id",
      headerName: "ID",

      width: 250,
    },
    {
      field: "pay_date",
      headerName: "Date",

      width: 250,

      // editable: true,
    },
    {
      field: "sub_name",
      headerName: "App Name",

      width: 250,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => customStatusRow(params.row),
      // type: "number",
      width: 250,
      editable: false,
    },
    {
      field: "type",
      headerName: " Payment Type",
      width: 250,
      editable: false,
    },
    // {
    //   field: "trans_id",
    //   headerName: "Transaction Id",

    //   sortable: true,
    //   width: 250,
    // },
    {
      field: "cost",
      headerName: "Amount",

      renderCell: (params) => customAmountRow(params.row),
      // type: "number",
      width: 250,
      editable: false,
    },
    {
      field: "total",
      headerName: `Total ( ${totalCost})`,

      renderCell: (params) => customTotalRow(params.row),
      // type: "number",
      width: 250,
      editable: false,
    },
  ];

  const customStatusRow = (rowData) => (
    <div>
      <div className={styles.statusRow}>
        <p
          style={{
            color: "#030303",

            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          {rowData.status}
        </p>
        <img
          src="/assets/successicon.svg"
          alt=""
          style={{ width: "12px", height: "12px" }}
        />
      </div>
    </div>
  );
  const customAmountRow = (rowdata) => (
    <div
      style={{
        width: "20%",
      }}
    >
      {rowdata.cost ? (
        <div
          className={styles.amountrow}
          style={{
            color: "#030303",

            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          {+rowdata.cost}
        </div>
      ) : (
        <div
          style={{
            color: "#030303",
            width: "100%",
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          0
        </div>
      )}
    </div>
  );
  const customTotalRow = (rowdata) => {
    console.log(rowdata, "rowdata");
    return (
      <div className={styles.amountrow}>
        <p
          className=""
          style={{
            color: "#030303",

            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          {`${rowdata.sub_name} total`}
        </p>
        <p
          className=""
          style={{
            color: "#030303",

            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {`$ ${+rowdata.cost}`}
        </p>
      </div>
    );
  };

  const sortedTransactions = [...allUserTransactionsData].sort((a, b) =>
    a.pay_date < b.pay_date ? -1 : a.pay_date > b.pay_date ? 1 : 0
  );
  // console.log(sortedTransactions, "sortedTransactions");

  let runningSums = {};
  const updatedTransactions = sortedTransactions.map((item) => {
    let subNameKey = item?.sub_name?.toLowerCase();
    if (!runningSums[subNameKey]) {
      runningSums[subNameKey] = 0;
    }
    runningSums[subNameKey] += parseInt(item.cost);
    return { ...item, total: runningSums[subNameKey] };
  });
  console.log(updatedTransactions, "updatedTransactions");
  // Now, if you want to render the transactions in descending order by date, sort them again:
  const renderedTransactions = updatedTransactions.sort((a, b) =>
    a.pay_date < b.pay_date ? 1 : a.pay_date > b.pay_date ? -1 : 0
  );

  // console.log(renderedTransactions, "renderedTransactions");

  // console.log(updatedTransactions, "updatedTransactions");

  return (
    <Box sx={{ height: "400px", width: "100%" }}>
      {/* <DataGrid
        columns={columns}
        rows={allUserTransactionsData}
        getRowId={() => sortedTransactions.map((id) => id.toString())}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      /> */}
      <DataGrid
        columns={columns}
        rows={allUserTransactionsData}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DataGridComponent;
