import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

// const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false });

// Use `DynamicApexChart` as a component in your render method or functional component

const BarChartComponent = ({ subscriptionAmountGraphData }) => {
  // console.log(subscriptionAmountGraphData,"subscriptionAmountGraphData")
  // const [state, setstate] = useState({
  //   options: {
  //     tooltip: {
  //       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //         const platform = state.series[seriesIndex].data[dataPointIndex];
  //         return (
  //           '<div class="custom-tooltip">' +
  //           `<div class="text-container">
  //           <p class="platform-text"> ${platform.x}</p>
  //           <p class="platform-text"> $ ${platform.y} </p></div>` +
  //           "</div>"
  //         );
  //       },
  //     },
  //     chart: {
  //       height: 350,
  //       type: "bar",
  //       events: {
  //         click: function (chart, w, e) {
  //           // console.log(chart, w, e)
  //         },
  //       },
  //     },
  //     colors: [
  //       "#26A0FC",
  //       "#26E7A6",
  //       "#FF6178",
  //       "#8B75D7",
  //       "#26tbnu",
  //       "#46B3A9",
  //       "#D830EB",
  //     ],
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "45%",
  //         distributed: true,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     legend: {
  //       show: false,
  //     },
  //     yaxis: {
  //       show: true,
  //     },
  //     xaxis: {
  //       categories: graph_data?.filter((item) => item.x).map((item) => item.x),
  //       labels: {
  //         style: {
  //           colors: [
  //             "#26A0FC",
  //             "#26E7A6",
  //             "#FF6178",
  //             "#8B75D7",
  //             "#26tbnu",
  //             "#46B3A9",
  //             "#D830EB",
  //           ],
  //           fontSize: "12px",
  //         },
  //       },
  //     },
  //   },
  // });
  let graph_data = subscriptionAmountGraphData?.[0]?.graph_data;

  if (!graph_data || graph_data.length === 0) {
    // Handle the case when data is missing or empty
    return <div>No data available for the chart.</div>;
  }

  // const categories = graph_data.map((item) => item.x);
  const seriesData = graph_data?.map((item) => ({ x: item.x, y: item.y }));
  // console.log(seriesData, "seriesData");
  const options = {
    series: graph_data?.map((item) => ({ x: item.x, y: item.y })),
    tooltip: {
      y: {
        formatter: function (value) {
          // console.log(value, "value");
          return ` $${value}`;
        },
      },
    },
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: [
      "#26A0FC",
      "#26E7A6",
      "#FF6178",
      "#8B75D7",
      "#26tbnu",
      "#46B3A9",
      "#D830EB",
    ],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: true,
    },
    xaxis: {
      categories: graph_data?.map((item) => item.x),
      labels: {
        style: {
          colors: [
            "#26A0FC",
            "#26E7A6",
            "#FF6178",
            "#8B75D7",
            "#26tbnu",
            "#46B3A9",
            "#D830EB",
          ],
          fontSize: "12px",
        },
      },
    },
  };
  // console.log(graph_data, "graph_data");

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[{ data: seriesData }]}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChartComponent;
