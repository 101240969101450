import axios from "axios";
import AppConstants from "../../constants/AppConstants";
const API = axios.create({ baseURL: AppConstants.baseURL });

// get routes

export const getUserSubscriptions = (data) =>
  API.get("/subscription/usersubsriptions", data);

export const getTopSubscriptions = () =>
  API.get("/subscription/topsubscriptions");

// post routes
export const getNotifications = (data) =>
  API.post("/subscription/notifications", data);

export const getRecommendedSubscriptions = () =>
  API.post("/subscription/getrecommendedsubscriptions");

export const addSubscription = (data) =>
  API.post("/subscription/addsubscription", data);
export const sendMailToUser = (data) =>
  API.post("/subscription/sendmailtouser", data);
export const addCurrentSubscriptionApi = (data) =>
  API.post("/subscription/currentsubscription", data);
export const getAllSubscriptions = () =>
  API.post("/subscription/allsubscription");
export const userSpending = (data) =>
  API.post("/subscription/userspending", data);

export const userSpendingDataApi = (data) =>
  API.post("/subscription/userspending", data);

export const recurringSubscriptionsApi = (data) =>
  API.post("/subscription/recurringsubscriptions", data);

export const getCalenderDataApi = (data) =>
  API.post("/subscription/calendersubscription", data);

export const allTransactionsApi = (data) =>
  API.post("/subscription/alltransactions", data);

export const allSubscriptionAmountsGraphApi = (data) =>
  API.post("/subscription/subscriptiongraph", data);

export const removeNotificationApi = (data) =>
  API.post("/subscription/removenotification", data);

export const getSubsDetails = (data) =>
  API.post("/subscription/subdetials", data);
export const getsubsgraphs = (data) =>
  API.post("/subscription/subsgraphs", data);
