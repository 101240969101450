import { useDispatch, useSelector } from "react-redux";
import SubscriprionCards from "../../common/commonComponents/SubscriptionCards";
import {
  ArrowButtonNext,
  ArrowButtonPrevious,
} from "../../miscComponents/CustomArrows";
import React, { useEffect } from "react";
import Slider from "react-slick";
import { currentSubscriptionList } from "../../../redux/features/subscriptionSlice";

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,

  nextArrow: (
    <ArrowButtonNext imgSrc={"/assets/rightArrow.png"} imgAlt="next-button" />
  ),
  prevArrow: (
    <ArrowButtonPrevious
      imgSrc={"/assets/prevArrow.png"}
      imgAlt="prev-button"
    />
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        // infinite: true,
        draggable: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // infinite: true,
        draggable: true,
        swipeToSlide: true,
      },
    },
  ],
};

const CarouselComponent = ({ currentSubsList }) => {
  return (
    // <div style={{ maxWidth: "100%", overflow: "hidden" }}>
    <Slider {...settings}>
      {currentSubsList?.map((item, index) => (
        <SubscriprionCards key={index} subItem={item} />
      ))}
    </Slider>
    // </div>
  );
};

export default CarouselComponent;
