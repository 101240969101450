import React from "react";
import styles from "./about.module.css";

const CultureSection = () => {
  return (
    <div className="pageWrapper">
      <div className={styles.aboutcontainer}>
        <h2 className="header_2" style={{ fontWeight: "700" }}>
          Culture that suits
        </h2>
        <p className="desc_texts">
          If you are someone looking for opportunities to grow and show your
          potential to the fullest, contact us, and we will provide you with all
          that you need for your growth with our work culture
        </p>
        <div className={styles.imagecontainer}>
          <img
            className="img"
            src={"/assets/aboutusimage.png"}
            alt="about-us"
          />
        </div>
      </div>
    </div>
  );
};

export default CultureSection;
