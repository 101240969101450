export const ArrowButtonNext = ({ imgSrc, imgAlt, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        border: "none",
        position: "absolute",
        right: "0.5%",
      }}
      className={className}
    >
      <div
        style={{
          position: "absolute",
          right: "0.5%",
          top: "-98%",
          width: "42px",
          height: "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "27px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 16%)",
        }}
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>
    </button>
  );
};

export const ArrowButtonPrevious = ({ imgSrc, imgAlt, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        border: "none",
        zIndex: "1",
        position: "absolute",
        bottom: "60%",
        left: "0.4%",
      }}
      className={className}
    >
      <div
        style={{
          position: "absolute",
          left: "1%",
          top: "-98%",
          width: "42px",
          height: "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "27px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 16%)",
        }}
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>
    </button>
  );
};
