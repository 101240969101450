import React from "react";
import HeroSection from "./HeroSection";
import FeaturesSection from "../commonComponents/FeaturesSection";
import { featurepagefeatures } from "../commonComponents/featuresJson";
import styles from "./features.module.css";

const Features = () => {
  return (
    <>
      <HeroSection />

      {featurepagefeatures
        .slice(1, featurepagefeatures.length)
        .map((item, id) => (
          <>
            {id % 2 === 0 ? (
              <div className="marg_100">
                <FeaturesSection feature={item} />
              </div>
            ) : (
              <div
                className={`wholeWidthContainer marg_100 ${styles.featurewrapper}`}
                style={{ background: "#F2F2F2" }}
              >
                <FeaturesSection feature={item} />
              </div>
            )}
          </>
        ))}
      <div
        className={`wholeWidthContainer marg_100 ${styles.featurewrapper}`}
        style={{ background: "#F2F2F2" }}
      >
        <FeaturesSection feature={featurepagefeatures[0]} />
      </div>
      {/* <div className="marg_100">
        <FeaturesSection feature={featurepagefeatures[1]} />
      </div> */}
    </>
  );
};

export default Features;
