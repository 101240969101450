import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import styles from "../../commonStyling/common.module.css";

const rows = [
  {
    sub_logo: "assets/netflix.svg",
    sub_name: "Netflix",
    cost: " $22.99/mo",
    action: "Manage",
  },
  {
    sub_logo: "assets/netflix.svg",
    sub_name: "Hulu",
    cost: " $22.99/mo",
    action: "Manage",
  },
  {
    sub_logo: "assets/netflix.svg",
    sub_name: "Amazon Prime",
    cost: "16.0",
    action: "Manage",
  },
  {
    sub_logo: "assets/netflix.svg",
    sub_name: "Amazon Prime2",
    cost: "16.1",
    action: "Manage",
  },
];
const columns = [
  {
    name: "sub_logo",
    label: "",
    options: { filter: true, sort: true },
  },
  { name: "sub_name", label: "Name", options: { filter: true, sort: true } },
  {
    name: "sub_description",
    label: "Description",
    options: { filter: true, sort: true },
  },
  { name: "cost", label: "Price", options: { filter: true, sort: true } },
  { name: "action", label: "Action", options: { filter: true, sort: true } },
];

export default function DataTable({
  tableColumns,
  tableRows,
  recomendedsubscription,
}) {
  const navigate = useNavigate();
  const handleManage = (row) => {
    navigate("/dashboard/add-new", { state: { ...row, openModal: true } });
  };

  // console.log(recomendedsubscription, "recomendedsubscription");
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "18px", background: "#FFF", padding: "10px 20px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableColumns
              ? tableColumns.map((column, index) => (
                  <TableCell key={index.toString()} align="right">
                    {column.label}
                  </TableCell>
                ))
              : columns.map((column, index) => (
                  <TableCell key={index.toString()} align="center">
                    {column.label}
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            recomendedsubscription?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <TableCell component="th" scope="row">
                  <img
                    src={`${row.sub_logo}`}
                    alt="subscription"
                    width={40}
                    height={40}
                  />
                </TableCell>
                {/* <img src="/assets/netflix.svg" alt="subscription"/> */}
                <TableCell align="right">
                  {" "}
                  <p
                    className={styles.tablevaluebold}
                    style={{ fontWeight: "700" }}
                  >
                    {row.sub_name}
                  </p>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <p
                    className={styles.tablevaluebold}
                    style={{ fontWeight: "700" }}
                  >
                    {row.sub_description}
                  </p>{" "}
                </TableCell>
                <TableCell align="right">
                  <p
                    className={styles.tablevaluebold}
                    style={{ fontWeight: "400" }}
                  >
                    {row.cost || " Not Declared"}
                  </p>
                </TableCell>
                <TableCell align="right">
                  <Button
                    className={`${styles.action_btn} ${styles.para_14}`}
                    variant="contained"
                    onClick={() => handleManage(row)}
                  >
                    {"Manage"}
                  </Button>
                </TableCell>
              </TableRow>
            ))
            // : rows.map((row, index) => (
            //     <TableRow
            //       key={index}
            //       sx={{
            //         "&:last-child td, &:last-child th": { border: 0 },
            //         borderBottom: "1px solid #E5E5E5",
            //       }}
            //     >
            //       <TableCell component="th" scope="row">
            //         <img
            //           src={`/${row.sub_logo}`}
            //           alt="subscription"
            //           width={35}
            //           height={35}
            //         />
            //       </TableCell>
            //       {/* <img src="/assets/netflix.svg" alt="subscription"/> */}
            //       <TableCell align="right">{row.sub_name}</TableCell>
            //       <TableCell align="right">{row.cost}</TableCell>
            //       <TableCell align="right">
            //         <Button
            //           className={`${styles.action_btn} ${styles.para_14}`}
            //           variant="contained"
            //         >
            //           {row.action}
            //         </Button>
            //       </TableCell>
            //     </TableRow>
            //   ))}
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
