import React from "react";
import styles from "./explore.module.css";
import { Rating, colors } from "@mui/material";
const JoinSection = () => {
  return (
    <div
      className="wholeWidthContainer marg_100"
      style={{ background: "#d0ee11" }}
    >
      <div className="pageWrapper">
        <div className={styles.joinSecContainer}>
          <h2
            className="header_2"
            style={{
              color: "#030303",
              fontWeight: "700",
              marginBottom: "18px",
            }}
          >
            Join 5 Million+ Members
          </h2>
          <div
            className={styles.sectionfooter}
            style={{ marginBottom: "23px" }}
          >
            <Rating
              name="half-rating-read"
              defaultValue={4.5}
              precision={0.5}
              readOnly
              size="large"
              sx={{ color: "#030303" }}
            />
            <p className="normal_sm_text">4.5 Stars (2k Ratings)</p>
          </div>
          <p className="dark_desc_texts">
            Building trust and saving money for our users through our all-in-one manager <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinSection;
