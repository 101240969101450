import React, { useEffect, useState } from "react";
import styles from "./addnew.module.css";
import substyles from "../PostLoginPages/SubscriptionList/subscription.module.css";
import SubscriprionCards from "../common/commonComponents/SubscriptionCards";
// import Image from "next/image";
// import Link from "next/link";
import ModalComponent from "./ModalComponent";
import { useDispatch } from "react-redux";
import {
  AddSubscription,
  GetALLSubscriptions,
} from "../../redux/features/subscriptionSlice";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
const CardsComponent = ({ allSubscriptions, state }) => {
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: state && state?.sub_name ? state?.sub_name : "",
    sub_description: "",
    cost: state && state?.price ? state?.price : state?.cost ? state?.cost : "",
    plan_name: "monthly",
    start_date: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "standard",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: null,
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (state && state?.openModal) {
      setOpen(true);
      console.log(state, "state");
      setNewSubsData({
        ...newSubsData,
        sub_id: state?.sub_id,
        sub_title: state?.sub_name,
      });
    }
  }, []);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubsDetails = (e) => {
    // console.log(e.target.value, "e.target.value");
    setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
  };
  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();

    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }
    console.log(newSubsData, "newSubsData");
    console.log(
      newSubsData.pay_method == "card" && newSubsData.card_no == "",
      "condition"
    );

    dispatch(AddSubscription(newSubsData))
      .then((res) => {
        message.success(res.payload);
        // console.log(res, "res of add new");
        // dispatch(GetALLSubscriptions());r
      })
      .catch((err) => message.error(err));
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "standard",
      sub_type: "paid",
      free_trial_days: "",
      sub_id: null,
    });
    handleClose();
  };
  // console.log(allSubscriptions, "allSubscriptions");

  return (
    <div className={substyles.subscriptionContainer}>
      {allSubscriptions?.map((item, index) => (
        <div className={substyles.cardWrapper} key={index}>
          <SubscriprionCards
            subItem={item}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleSubsDetails={handleSubsDetails}
            setNewSubsData={setNewSubsData}
            newSubsData={newSubsData}
            handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          />
        </div>
      ))}

      <div
        className={substyles.cardWrapper}
        style={{ cursor: "pointer", height: "270px" }}
        onClick={handleOpen}
      >
        <div className={styles.planecardcontainer}>
          <img
            src="/assets/plusicon.svg"
            className="image"
            style={{ objectFit: "fill" }}
          />
        </div>
      </div>
      <ModalComponent
        open={open}
        userData={userData}
        setOpen={setOpen}
        handleClose={handleClose}
        handleSubsDetails={handleSubsDetails}
        setNewSubsData={setNewSubsData}
        newSubsData={newSubsData}
        handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
      />
    </div>
  );
};

export default CardsComponent;
