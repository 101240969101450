import React, { useEffect, useRef, useState } from "react";

// import { useRouter } from "next/router";
import _ from "lodash";
import styles from "./login.module.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
import { EmailLoginSchema } from "../../utils/auth_validator.js";
import GoogleIcon from "@mui/icons-material/Google";
import AppConstants from "../../constants/AppConstants.js";
import { Link } from "react-router-dom";

const Login = (props) => {
  // const router = useRouter();
  const [showPass, setshowPass] = useState({
    createPass: true,
  });
  const [formErrors, setformErrors] = useState("");

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const { login, errorMsg } = useAuth();
  const auth = useAuth();
  console.log(auth, "autjh");
  const handleGoogleSignIn = async () => {

    window.open(
      `${AppConstants.baseURL}/auth/google`,
      "_self"
    );
    // console.log(window.location.href);
    // document.cookie = `location_to_redirect = ${
    //   window.location.href
    // }; expires=${24 * 60 * 60 * 1 * 1000}; path = /;`;
    // await router.push("https://alladdinx.com/api/auth/google");
    // await googleLogin();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const enterEmail = emailInputRef.current.value;
      const enteredPassword = passwordInputRef.current.value;

      let loginData = {};

      loginData.email = enterEmail;

      loginData.password = enteredPassword;

      setformErrors(validate(loginData));

      if (enterEmail) {
        const isvalid = await EmailLoginSchema.isValid(loginData);
        // console.log(isvalid, "of email");
        if (isvalid) {
          let res = await login(loginData);
          //   message.success("loggedIn");
          // console.log(res, "errorMsg in if");
          if (!errorMsg) {
            // hide();
          }

          // resetModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (values) => {
    const errors = {};
    const emailreRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email id or Phone is required!";
    } else if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length <= 7) {
      errors.password = "Password is to short";
      message.error("password must be atleast 8 characters long");
    } else if (!emailreRegex.test(values.email)) {
      errors.email = "Email is in incorrect format";
      message.error("Email is in incorrect format");
    }

    return errors;
  };

  // console.log(loginMessage, "loginMessage in modal");

  return (
    <>
      {/*---------- modal body when loginwithemailandpass = true -------- */}
      <div className={styles.main_parent_div}>
        <div className={styles.main}>
          <div className={styles.child}>
            <div className={styles.heading}>
              <div className={styles.para}>
                <p>
                  Simplify Your
                  <span id={styles.subs}> Subscriptions,</span>
                  <br />
                  Amplify Your Savings
                </p>
              </div>
              <div className={styles.imageLogoContainer}>
                <img src="/assets/alladdin_logo.png" alt="brandmark" />
              </div>
            </div>
            <div className={styles.details}>
              <div class={styles.details_child}>
                <div className={styles.signIn}>
                  <p>Sign-in</p>
                </div>
                <div>
                  <Form className="modal_body_form" onSubmit={handleSubmit}>
                    <Form.Group
                      className={styles.input_field}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Enter Email, Phone No.</Form.Label>
                      <Form.Control
                        className="modalInp"
                        type="text"
                        placeholder="Enter your email"
                        ref={emailInputRef}
                        name="email"
                        required
                        autoComplete="false"
                        style={{ height: "36px" }}
                      />
                    </Form.Group>

                    <Form.Group
                      className={styles.input_field}
                      controlId="formBasicPassword"
                    >
                      <div className="label_wrapper">
                        <Form.Label>Password</Form.Label>
                      </div>
                      <div className={styles.inputwrapper}>
                        <div className={styles.input_groups}>
                          <input
                            style={{ height: "36px" }}
                            name="password"
                            required
                            type={showPass.createPass ? "password" : "text"}
                            className="form-control password_input"
                            placeholder="Enter your password"
                            ref={passwordInputRef}
                            autoComplete="false"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn eye_btn"
                              type="button"
                              onClick={() =>
                                setshowPass({
                                  ...showPass,
                                  createPass: !showPass.createPass,
                                })
                              }
                              style={{ border: "none", background: "none" }}
                            >
                              {showPass.createPass ? (
                                <RemoveRedEyeIcon style={{ color: "#000" }} />
                              ) : (
                                <VisibilityOffIcon style={{ color: "#000" }} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div className={styles.btn_grps}>
                      <div className={styles.w_50}>
                        <button
                          className={styles.Login_btn}
                          variant="primary"
                          type="submit"
                          size="lg"
                        >
                          Log in
                        </button>
                      </div>
                      <div className={styles.w_50}>
                        <button
                          // variant="outline-dark"
                          className={styles.google_btn}
                          type="button"
                          onClick={() => handleGoogleSignIn()}
                        >
                          <GoogleIcon
                            style={{ marginRight: "10px", color: "#d0ee11" }}
                          />
                          Google Login
                        </button>
                      </div>
                    </div>
                    <div className={styles.create_acc}>
                      <p className={styles.signUp_text}>
                        Don’t have an account?&nbsp;
                        <Link
                          to="/signup"
                          style={{
                            color: "#3E6EDC",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          Create now
                        </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*---------- modal footer when loginwithemailandpass = true -------- */}
    </>
  );
};

export default Login;
