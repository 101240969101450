import React, { useState } from "react";
import styles from "./features.module.css";
import axios from "axios";
import Appconstants from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";

const FeatureCard = ({ feature }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const handleCheckout = (price = 0) => {
    console.log(price, "clickeed");
    // let data = { price };
    if (userData) {
      try {
        setLoading(true);
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        fetch(`${Appconstants.baseURL}/subscription/create-checkout-session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ price: price }),
        })
          .then((res) => {
            if (res.ok) return res.json();
            console.log(res);
            return res.json().then((json) => Promise.reject(json));
          })
          .then(({ session }) => {
            setLoading(false);
            window.location = session.url;
          })
          .catch((e) => {
            console.log(e.error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <div className={styles.featurecardcontainer}>
      <div className={styles.featurecardHeader}>
        <div className={`${styles.premium_text}`}>
          <p
            className={`desc_texts `}
            style={{ color: "#2F2F2F", fontWeight: "800" }}
          >
            {feature.planTitle}
          </p>
        </div>
        {feature.isSaving ? (
          <button className={styles.featureBtn} style={{ padding: "8px 10px" }}>
            <p className={styles.savingbtnText}> save {feature.savings}</p>
          </button>
        ) : (
          <></>
        )}
      </div>
      {feature.fees ? (
        <div className={styles.pricingSection}>
          <div className={styles.leftsec}>
            <p className={styles.prevfees}>{feature.planprevfeesMonthly}</p>
            <p className={styles.newfees}>{feature.planfeesMonthly}</p>
          </div>
          <div className={styles.rightsec}>
            <p className={styles.annualfees}>{feature.annualbill}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={styles.featureFooter}>
        <div className={styles.featurenumcontainer}>
          {feature.planFeatures.map((item, id) => (
            <div className="flex items-center gap-[2px]" key={id}>
              <img src={"/assets/righttickicon.svg"} width={10} height={10} />
              <p className={styles.features}>{item.feature}</p>
            </div>
          ))}
        </div>
        <button
          className={styles.featureBtn}
          onClick={() => handleCheckout(feature.costs)}
          disabled={loading}
          style={{
            opacity: loading ? 0.5 : 1,
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {feature.buttonText}
        </button>
      </div>
    </div>
  );
};

export default FeatureCard;
