import React from "react";
import styles from "./explore.module.css";

const HeroSection = () => {
  return (
    <div className="wholeWidthContainer">
      <div className="pageWrapper">
        <div className={`${styles.heroContainer}`}>
          <div className={styles.leftSection}>
            <h1 className="header_1 marg_16">
              Optimize Your Savings by Streamlining Your Subscriptions
            </h1>
            <p
              className="desc_texts marg_32"
              style={{ color: "#6D6253", fontWeight: "400" }}
            >
              Take control of subscriptions and avoid unnecessary fees. Our tracker sends alerts before payments. Know exactly what you're paying for..
            </p>
            <div className={styles.sectionfooter}>
              <button className="cta_btn">Install Extension</button>
              <p className="text_sm">Start 7 days free trial</p>
            </div>
          </div>
          <div className={styles.rightSection}>
            {/* <div className={styles.aladinimgContainer}>
            <Image
              src="/assets/aladinicon.svg"
              className="img"
              alt="alladinicon"
              fill
            />
          </div> */}
            <img
              src="/assets/herotabimg.png"
              alt={"hero_image"}
              className="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
