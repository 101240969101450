import React from "react";
import styles from "./explore.module.css";
import TestimonialCard from "./TestimonialCard";
import { ImageList, ImageListItem } from "@mui/material";

const Testimonials = () => {

  const testimonials = [
    {
      id: 0,
      testimonial:
        "Explore tales of transformation from those who have experienced the magic of our extension.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 1,
      testimonial:
        "With Alladdin,  I was able to save a lot on all my subscriptions, never giving up on the website again.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 2,
      testimonial:
        "Managing all my subscriptions has never been easier; Aladdin works like a genie for me.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 3,
      testimonial:
        "I was tired of checking all my subscriptions over and over again. Thanks to Alladdin, I can now get all of them in one place without any extra effort.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 4,
      testimonial:
        "My subscriptions used to get renewed automatically. Then someone told me about Alladdin. Now, Alladdin notifies me every time my subscription is about to expire, and I can manage it without spending extra money.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 5,
      testimonial:
        "Definitely recommend Alladdin to my friends out there as it helped me manage my subscriptions and save almost Rs. 600 in a single year. This website is like a game changer for managing my subscriptions.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 6,
      testimonial:
        "We've heard great feedback from users who have experienced the magic of saving on subscriptions with Alladdin.",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 7,
      testimonial:
        "One user shared how Alladdin helped them save a lot and made managing subscriptions easy, calling the site a  \"genie\" for their needs. Another appreciated being able to see all subscriptions in one place with no extra effort, as checking multiple sites before was tiring. ",
      author: "Samuel B.",
      stars: 4,
    },
    {
      id: 8,
      testimonial:
        "Previously dealing with automatic renewals, one user told of how Alladdin now notifies them before expiration so they can manage costs without surprises. They saved close to $600 in a year and highly recommend Alladdin to friends for its subscription management help. Overall, many find Alladdin a real \"game changer\" for this task.",
      author: "Samuel B.",
      stars: 4,
    },
  ];

  return (
    <div className={`pageWrapper marg_100`}>
      <div className={styles.testimonialContainer}>
        <div className={styles.headerContainer}>
          <h2
            className="header_2"
            style={{ color: "#030303", fontWeight: "600" }}
          >
            Hear From Our Users!
          </h2>
          <p className="normal_sm_text" style={{ color: "#606060" }}>
            Explore tales of transformation from those who have experienced the
            magic of our extension.
          </p>
        </div>

        <div className={styles.cardContainer}>
          <ImageList className={styles.testimonialList}>
            {testimonials.map((item, id) => (
              <ImageListItem key={id}>
                <TestimonialCard testimonial={item} />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
