import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as subsApi from "../api/subs";

const initialState = {
  loading: false,
  notifications: [],
  recomendedsubscription: [],
  allSubscriptions: [],
  currentSubsList: [],
  userSpendData: [],
  recurringDataUpcoming: [],
  recurringDataAll: [],
  subscriptionAmountGraphData: [],
  calenderData: [],
  allUserTransactionsData: [],
  perticularSubsData: "",
  perticularSubsCostData: "",
  perticularGraphData: [],
  successfullmessage: "",
  error: "",
};

export const GetUserSubscriptions = createAsyncThunk(
  "subscription/usersubsriptions",
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getUserSubscriptions(data);
      if (resp.data.code == 200) {
        return resp.data;
      } else if (resp.data.code == 402) {
        console.log(resp.data.message);
        return resp.data.attempts;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// export const showNotifications = createAsyncThunk(
//   "subscription/shownotification",
//   async (data, { rejectWithValue }) => {
//     try {
//       const resp = await subsApi.getNotifications(data);
//       if (resp.data.code == 200) {
//         return resp.data.data;
//       } else {
//         console.log(resp.data.message, "message");
//         return rejectWithValue(resp.data.message);
//       }
//     } catch (error) {
//       console.log(error, "error shownotification");
//       rejectWithValue(error.resp.data);
//     }
//   }
// );
export const showNotifications = createAsyncThunk(
  "subscription/shownotification",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getNotifications(data);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const userSpendingData = createAsyncThunk(
  "subscription/userspending",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.userSpendingDataApi(formData);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const recurringSubscriptionsData = createAsyncThunk(
  "subscription/recurringsubscriptions",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.recurringSubscriptionsApi(formData);
      if (resp.data.code == 200) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const getCalenderData = createAsyncThunk(
  "subscription/calendersubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getCalenderDataApi(formData);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetALLSubscriptions = createAsyncThunk(
  "subscription/getallsubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAllSubscriptions();
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetRecommendedSubscriptions = createAsyncThunk(
  "subscription/getrecommendedsubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getRecommendedSubscriptions();
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const currentSubscriptionList = createAsyncThunk(
  "subscription/currentsubscription",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.addCurrentSubscriptionApi();
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const allTransactionsData = createAsyncThunk(
  "subscription/alltransactions",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.allTransactionsApi(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const allSubscriptionAmountsGraph = createAsyncThunk(
  "subscription/subscriptiongraph",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.allSubscriptionAmountsGraphApi(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);
export const AddSubscription = createAsyncThunk(
  "subscription/addsubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.addSubscription(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.message;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);
export const GetSubsDetails = createAsyncThunk(
  "subscription/getSubsDetails",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getSubsDetails(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);
export const Getsubsgraphs = createAsyncThunk(
  "subscription/getsubsgraphs",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getsubsgraphs(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);
export const removeNotification = createAsyncThunk(
  "subscription/removenotification",
  async (formData, { rejectWithValue }) => {
    try {
      let result = await subsApi.removeNotificationApi(formData);

      if (result?.data?.code == 200)
        message.success("Removed notification successfully");
      else message.error(result?.data?.message, "Error removing notification");
      return result?.data;
    } catch (error) {
      // message.error(error, "Error removing notification");
      return rejectWithValue(error);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    });
    builder.addCase(showNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(removeNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeNotification.fulfilled, (state, action) => {
      state.loading = false;
      // state.notifications = action.payload;
    });
    builder.addCase(removeNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetRecommendedSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetRecommendedSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.recomendedsubscription = action.payload;
    });
    builder.addCase(GetRecommendedSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetALLSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetALLSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.allSubscriptions = action.payload;
    });
    builder.addCase(GetALLSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(currentSubscriptionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(currentSubscriptionList.fulfilled, (state, action) => {
      state.loading = false;
      state.currentSubsList = action.payload;
    });
    builder.addCase(currentSubscriptionList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(userSpendingData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userSpendingData.fulfilled, (state, action) => {
      state.loading = false;
      state.userSpendData = action.payload;
    });
    builder.addCase(userSpendingData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(recurringSubscriptionsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recurringSubscriptionsData.fulfilled, (state, action) => {
      state.loading = false;
      state.recurringDataUpcoming = action.payload.data;
      state.recurringDataAll = action.payload.data1;
    });
    builder.addCase(recurringSubscriptionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getCalenderData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCalenderData.fulfilled, (state, action) => {
      state.loading = false;
      state.calenderData = action.payload;
    });
    builder.addCase(getCalenderData.rejected, (state, action) => {
      state.loading = false;
      state.calenderData = [];
      state.error = action.payload;
    });

    builder.addCase(allTransactionsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allTransactionsData.fulfilled, (state, action) => {
      state.loading = false;
      state.allUserTransactionsData = action.payload;
    });
    builder.addCase(allTransactionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(allSubscriptionAmountsGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allSubscriptionAmountsGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.subscriptionAmountGraphData = action.payload;
    });
    builder.addCase(allSubscriptionAmountsGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(AddSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.successfullmessage = action.payload;
    });
    builder.addCase(AddSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successfullmessage = action.payload;
    });

    builder.addCase(GetSubsDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetSubsDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.perticularSubsData = action.payload.data;
      state.perticularSubsCostData = action.payload.user_sub_data;
    });
    builder.addCase(GetSubsDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.successfullmessage = action.payload;
    });

    builder.addCase(Getsubsgraphs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Getsubsgraphs.fulfilled, (state, action) => {
      state.loading = false;
      state.perticularGraphData = action.payload;
    });
    builder.addCase(Getsubsgraphs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.successfullmessage = action.payload;
    });
  },
});

export default subscriptionSlice.reducer;
