import React from "react";
import ParentHeroComponent from "../commonComponents/ParentHeroComponent";
import styles from "./contact.module.css";

const HeroSection = () => {
  const contactInfo = [
    {
      icon: "/assets/emailicon.svg",
      info: "contact@Alladdin",
    },
    {
      icon: "/assets/phoneicon.svg",
      info: "XXXXXXXXXXXX54",
    },
  ];
  return (
    <ParentHeroComponent>
      <div className="pageWrapper">
        <div className={styles.contactHeroContainer}>
          <div className={styles.leftSec}>
            <h1 className="header_1">We Are Here</h1>

            <div className={styles.contactinfosec}>
              {contactInfo.map((contact, id) => (
                <div key={id.toString()} className={styles.contactinfo}>
                  <img
                    src={contact.icon}
                    width={30}
                    height={20}
                    alt={contact.info}
                  />
                  <p className="text_sm" style={{ fontWeight: "700" }}>
                    {contact.info}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.rightSec}>
            <img
              fill
              className="img"
              src={"/assets/contacthero.png"}
              alt="contact-us"
            />
          </div>
        </div>
      </div>
    </ParentHeroComponent>
  );
};

export default HeroSection;
