import React, { useEffect, useState } from "react";
import DataTable from "../../common/commonComponents/Datatable/Datatable";
import styles from "./recurring.module.css";
import commonstyles from "../../common/commonStyling/common.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, Router } from "react-router-dom";

import { Button } from "@mui/material";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalenderData,
  recurringSubscriptionsData,
} from "../../../redux/features/subscriptionSlice";
import CalenderView from "../../common/commonComponents/Calender";
import { useNavigate } from "react-router-dom";
function Recurring() {
  const [activeTab, setActiveTab] = useState("upcoming");

  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());
  const dispatch = useDispatch();
  const { recurringDataUpcoming, recurringDataAll } = useSelector((state) => ({
    ...state.subscription,
  }));
  // console.log(value, "value");
  // const onChange = () => {};
  const tabs = [
    {
      tabName: "Upcoming",
      tabValue: "upcoming",
    },
    {
      tabName: "All Apps",
      tabValue: "all",
    },
    {
      tabName: "Calender",
      tabValue: "calender",
    },
  ];
  const handleTabClick = (activetab) => {
    setActiveTab(activetab);
  };

  const columns = [
    {
      name: "sub_logo",
      label: "Logo",
      // options: { filter: true, sort: true },
    },
    { name: "sub_name", label: "Name", options: { filter: true, sort: true } },
    { name: "cost", label: "Price", options: { filter: true, sort: true } },
    {
      name: "RenewalDate",
      label: "Renewal Date",
      options: { filter: true, sort: true },
    },
    // {
    //   name: "Lastvisited",
    //   label: "Last visited",
    //   options: { filter: true, sort: true },
    // },
    {
      name: "DateCreated",
      label: "Date Created",
      options: { filter: true, sort: true },
    },
    { name: "action", label: "Action", options: { filter: true, sort: true } },
  ];

  useEffect(() => {
    if (activeTab === "upcoming" || activeTab === "all") {
      let data = { type: activeTab };
      dispatch(recurringSubscriptionsData(data));
    }
  }, [activeTab]);

  const handleManage = (row) => {
    console.log(row, "row in handleManage");
    navigate("/dashboard/add-new", { state: { ...row, openModal: true } });
  };
  // console.log(recurringDataAll, recurringDataUpcoming, "12345");
  return (
    <>
      <h3
        className="postloginHeading"
        style={{ marginBottom: "8px", fontWeight: "500" }}
      >
        Recurring Susbcriptions
      </h3>
      <div className={styles.tabsContainer}>
        {tabs.map((tab, id) => (
          <div
            style={{ cursor: "pointer" }}
            className={
              tab.tabValue === activeTab ? styles.tabActive : styles.tab
            }
            key={id}
            onClick={() => handleTabClick(tab.tabValue)}
          >
            <p className={styles.tabtext}>{tab.tabName}</p>
          </div>
        ))}
      </div>
      <div style={{ padding: "0px 20px" }}>
        {activeTab === "upcoming" || activeTab === "all" ? (
          <div>
            {(activeTab === "all" && recurringDataAll?.length > 0) ||
            (activeTab === "upcoming" && recurringDataUpcoming?.length > 0) ? (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "18px",
                  background: "#FFF",
                  padding: "10px 20px",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell key={index.toString()} align="center">
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeTab === "all"
                      ? recurringDataAll?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderBottom: "1px solid #E5E5E5",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <img
                                src={row.sub_logo}
                                alt="subscription"
                                width={35}
                                height={35}
                              />
                            </TableCell>
                            {/* <img src="/assets/netflix.svg" alt="subscription"/> */}
                            <TableCell align="center">{row.sub_name}</TableCell>
                            <TableCell align="center">{row.price}</TableCell>
                            <TableCell align="center">
                              {row.renewal_date}
                            </TableCell>
                            {/* <TableCell align="center">
                              {row.last_visited}
                            </TableCell> */}
                            <TableCell align="center">
                              {row.days_left}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "14px",
                              }}
                            >
                              <Button
                                className={`${styles.action_btn} ${styles.para_14}`}
                                variant="contained"
                                onClick={() => handleManage(row)}
                              >
                                {"Manage"}
                              </Button>
                              {/* <Link to={"#"} className={styles.editButton}>
                                <img
                                  src="/assets/editicon.svg"
                                  alt="edit icon"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              </Link> */}
                            </TableCell>
                          </TableRow>
                        ))
                      : recurringDataUpcoming?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderBottom: "1px solid #E5E5E5",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <img
                                src={row.sub_logo}
                                alt="subscription"
                                width={35}
                                height={35}
                              />
                            </TableCell>
                            {/* <img src="/assets/netflix.svg" alt="subscription"/> */}
                            <TableCell align="center">{row.sub_name}</TableCell>
                            <TableCell align="center">{row.price}</TableCell>
                            <TableCell align="center">
                              {row.renewal_date}
                            </TableCell>
                            <TableCell align="center">
                              {row.last_visited}
                            </TableCell>
                            <TableCell align="center">
                              {row.days_left}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "14px",
                              }}
                            >
                              <Button
                                className={`${styles.action_btn} ${styles.para_14}`}
                                variant="contained"
                                onClick={() => handleManage(row)}
                              >
                                {"Manage"}
                              </Button>
                              {/* <Link to={"#"} className={styles.editButton}>
                                <img
                                  src="/assets/editicon.svg"
                                  alt="edit icon"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              </Link> */}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p className="text_sm">no data found................</p>
            )}
          </div>
        ) : (
          <div className={""}>
            <CalenderView fullSizeCalendar={"fullSizeCalendar"} />
          </div>
        )}
      </div>
    </>
  );
}

export default Recurring;
