import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getCalenderData } from "../../../../redux/features/subscriptionSlice";
import { Tooltip } from "react-tooltip";
import { Spin, Avatar, List } from "antd";

function CalenderView({ fullSizeCalendar, inlineCalendar }) {
  const params = window.location.pathname;
  const { calenderData } = useSelector((state) => ({
    ...state.subscription,
  }));
  const [loadCalender, SetLoadCalender] = useState(false);
  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState([
    new Date("2023-10-10"),
    new Date("2023-10-15"),
    new Date("2023-11-05"),
  ]);
  const [tooltipContent, setTooltipContent] = useState(null);

  const events = {
    "2023-10-10": "Event 1",
    "2023-10-15": "Event 2",
    "2023-11-05": "Event 3",
  };

  const isDateHighlighted = (date) =>
    selectedDates.some(
      (selectedDate) =>
        selectedDate.getDate() === date.getDate() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getFullYear() === date.getFullYear()
    );

  useEffect(() => {
    SetLoadCalender(true);
    let data = {
      calender_month: new Date().getMonth() + 1,
      calender_year: new Date().getFullYear(),
    };
    dispatch(getCalenderData(data)).then((res) => {
      // console.log(res, "res");
      let dates = [];
      if (Array.isArray(res?.payload)) {
        res?.payload?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
      }
      SetLoadCalender(false);
    });
  }, []);
  const handleDateMouseOver = (date) => {
    // Show Tooltip and set its content when hovering over a selected date
    // setSelectedDates(date);
    setTooltipContent(events[date.toISOString().split("T")[0]]);
  };

  const handleDateMouseOut = () => {
    // Hide Tooltip when mouse leaves the selected date
    // setSelectedDates(null);
    setTooltipContent(null);
  };

  return (
    <>
      {loadCalender ? (
        <Spin tip="Loading" size="large" className={"mt-8"}>
          <div className="content mt-8" />
        </Spin>
      ) : (
        <>
          <DatePicker
            highlightDates={selectedDates}
            calendarClassName={`${fullSizeCalendar} ${inlineCalendar}`}
            inline
            isClearable
            dayClassName={(date) =>
              isDateHighlighted(date) ? "highlighted-date" : undefined
            }
            disabledKeyboardNavigation
            shouldCloseOnSelect={false}
          ></DatePicker>
          {params.includes("recurring") && (
            <List
              itemLayout="horizontal"
              className="mt-5"
              dataSource={calenderData.filter((item) => item.sub_name)}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar key={index} src={item.sub_logo} />}
                    title={<p key={index}>{item.sub_name}</p>}
                    description={<p key={index}>{item.end_date}</p>}
                  />
                </List.Item>
              )}
            />
          )}
        </>
      )}

      {/* <Tooltip id="my-tooltip" /> */}
    </>
  );
}

export default CalenderView;

{
  /* {selectedDates.map((date, index) => (
        <a
          key={index}
          data-tooltip-id={`tooltip-${index}`}
          data-tooltip-content={events[date.toISOString().split("T")[0]]}
          data-tooltip-place="top"
          className="selected-date"
        >
          {date.getDate()}
        </a>
      ))}
      {selectedDates.map((date, index) => (
        <Tooltip
          key={index}
          id={`tooltip-${index}`}
          effect="solid"
          place="top"
        />
      ))} */
}
