"use client";
import React, { useState, useEffect } from "react";
// import Link from "next/link";
// import { useRouter } from "next/navigation";
import axios from "axios";
import styles from "./signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
import AppConstants from "../../constants/AppConstants";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const { signupUser } = useAuth();

  // const router = useRouter();
  // const handleclick = async () => {
  //   // console.log("first");
 
  //   window.open(
  //     `${AppConstants.baseURL}/auth/google`,
  //     "_self"
  //   );
  // };

  const handleclick = async() => {
    // console.log("first");
    // const resp = await axios.post(`${AppConstants.baseURL}/auth/logout`, {
    //   withCredentials: true,
    // });
    window.open(
      "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Falladdinx.com%2Fapi%2Fauth%2Fgoogle%2Fcallback&scope=profile%20email&client_id=210434425102-nn9m8fde1roabp80dvl913o4sqv5c9dj.apps.googleusercontent.com&service=lso&o2v=2&flowName=GeneralOAuthFlow",
      "_self"
    );
  };


  const validateEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const validPasswordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        email: isValidEmail ? "" : "Please enter a valid email address",
      }));
    }

    if (name === "password") {
      const isValidPassword = validatePassword(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        password: isValidPassword
          ? ""
          : "Password should contain special characters and alphapets",
      }));
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(formData.email);
    const isValidPassword = validatePassword(formData.password);

    setValidationMessages({
      email: isValidEmail ? "" : "Please enter a valid email address",
      password: isValidPassword
        ? ""
        : "Password should contain special characters and alphapets",
    });

    if (isValidEmail && isValidPassword) {
      if (formData.confirmPassword === formData.password) {
        // console.log("Form submitted:", formData);
        let datatoSend = {};
        datatoSend.name = formData.name;
        datatoSend.email = formData.email;
        datatoSend.password = formData.password;
        await signupUser(datatoSend);
      } else {
        message.error("Password and ConfirmPassword do not match");
      }
      // Form submission logic here

      // let resp = await axios.post(
      //   "https://alladdinx.com/api/auth/signup",
      //   // "http://localhost:5800/api/auth/signup",
      //   formData
      // );
      // console.log(resp);

      // if (resp && resp?.data?.code == 200) {
      //   // alert(resp.data.message ? resp.data.message : "user register");
      //   message.success(
      //     resp.data.message ? resp.data.message : "user register"
      //   );
      //   navigate("/dashboard");

      //   // console.log(resp.data.message ? resp.data.message : "user register");
      //   // router.push("/login");
      //   // let getProfile_resp = await axios.get(
      //   //   // "https://alladdinx.com/api/auth/profile",
      //   //   "http://localhost:5800/api/auth/profile"
      //   // );
      //   // console.log(getProfile_resp, "getProfile_resp");
      // } else {
      //   // alert(resp.data.message ? resp.data.message : "something went wrong");
      //   message.error(
      //     resp.data.message ? resp.data.message : "something went wrong"
      //   );

      //   // console.log(
      //   //   resp.data.message ? resp.data.message : "user not register"
      //   // );
      // }
    } else {
      if (!isValidEmail) {
        message.error("Please enter a valid email address");
      }
      if (!isValidPassword) {
        message.error(
          "Password should contain special characters and alphapets"
        );
      }
    }
  };

  // console.log(validationMessages, "validationMessages");

  return (
    <div className={styles.main}>
      <div className={styles.child}>
        <div className={styles.child_1}>
          <div className={styles.child_1_div}>
            <div className={styles.child_1_1}>
              <p>Simplify your</p>
              <p id="subs">Subscriptions,</p>
              <p>Amplify your</p>
              <p>Savings</p>
            </div>
            <div className={styles.child_1_2}>
              <img src="/assets/alladinblacklogo.png" alt="alladin" />
            </div>
          </div>
        </div>
        <div className={styles.child_2}>
          <div className={styles.child_2_div}>
            <div className={styles.child_2_1}>
              <p>Create Account</p>
            </div>

            <div className={styles.child_2_4}>
              <div className={styles.input_field}>
                <input
                  placeholder="Enter Your Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  type="text"
                />
              </div>
              <div className={styles.input_field}>
                <input
                  placeholder="Enter Your Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.input_field}>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.input_field}>
                <input
                  type="password"
                  placeholder=" Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </div>
              <p className={styles.Login_btn}>
                <button type="submit" onClick={handleSignup}>
                  Create Account
                </button>
              </p>
              <p className={styles.Sign_up_here}>
                Already have a account?{" "}
                <span>
                  <Link Link to={"/login"}>
                    Login
                  </Link>
                </span>
              </p>
            </div>
            <div className={styles.child_2_3}>
              <p>- OR -</p>
            </div>
            <div className={styles.child_2_2}>
              <button onClick={handleclick}>Sign up with Google</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

// {
//    <div className={styles.Singup_main_container}>
//       <div className={styles.main_singup_container}>
//         <div className={styles.mainContainer}>
//           <div className={styles.singup_heading_div}>
//             <h1 className={styles.singup_heading}>Create Account</h1>
//           </div>
//           <form onSubmit={handleSignup}>
//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Name</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Full Name"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleInputChange}
//                   type="text"
//                   className={styles.Singup_Name_inputbox}
//                 />
//               </div>
//             </div>

//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Email</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Email"
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                   className={styles.Singup_Name_inputbox}
//                 />
//                 {validationMessages.email && (
//                   <p id="password_11">{validationMessages.email}</p>
//                 )}
//               </div>
//             </div>

//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Password</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Password"
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleInputChange}
//                   className={styles.Singup_Name_inputbox}
//                 />
//                 {validationMessages.password && (
//                   <p id="password_11">{validationMessages.password}</p>
//                 )}
//               </div>
//             </div>

//             <div className={styles.singup_btn_class}>
//               <button className={styles.login_btn}>Signup</button>
//             </div>
//           </form>

//           <div className={styles.googlesignup} onClick={handleclick}>
//             <img
//               src={"/assets/googleimg.png"}
//               className={styles.google_login_img}
//             />
//             <span className={styles.with_google}>Continue with Google</span>
//           </div>

//           <div className={styles.singup_create_acc}>
//             <div className={styles.singup_create_}>
//               Already have an account?
//               <span>
//                 {/* <Link href="/login">Login</Link> */}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
// }
