import React, { useState } from "react";
import styles from "./profile.module.css";
import contactStyles from "../../contactus/contact.module.css";
import substyles from "../../subscription/sub.module.css";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import Appconstants from "../../../constants/AppConstants";
import axios from "axios";
import { message } from "antd";

function Profile() {
  const { userData, isUserSubscribed } = useAuth();
  const [loading, setLoading] = useState(false);
  const [buttontext, setbuttontext] = useState(isUserSubscribed);
  // console.log(userData, "userData");
  const navigate = useNavigate();
  const [profileState, SetProfileState] = useState({
    fullName: userData?.name ? userData?.name : "",
    company: "",
    email: userData?.email ? userData?.email : "",
    phone: "",
    password: "",
  });

  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (userData && isUserSubscribed) {
      // navigate("/dashboard/manage-subscription");
      try {
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(`${Appconstants.baseURL}/subscription/cancelsubscription`, {
          method: "POST",
        })
          .then((res) => {
            console.log(res, "res in cancel");
            if (res.ok) {
              message.success(
                res?.data?.message
                  ? res?.data?.message
                  : "Subscription Canceled"
              );
              window.location.reload();
            } else {
              message.error(
                res?.data?.message ? res?.data?.message : "Something Went Wrong"
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e.error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error, "error");
        setLoading(false);
      }
    } else {
      try {
        let price = 4.99;
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(
          `${Appconstants.baseURL}/subscription/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ price: price }),
          }
        )
          .then((res) => {
            if (res.ok) return res.json();
            console.log(res);
            return res.json().then((json) => Promise.reject(json));
          })
          .then(({ session }) => {
            setLoading(false);
            window.location = session.url;
          })
          .catch((e) => {
            console.log(e.error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <p className="text_sm" style={{ color: "#777" }}>
          Basic Details
        </p>
        {/* <div
          className="flex gap-[9px] items-center"
          style={{ cursor: "pointer" }}
          onClick={handleEdit}
        >
          {!editable ? (
            <>
              <p className="desc_texts">Edit</p>
              <img
                src="/assets/editicon.svg"
                alt="edit"
                style={{ width: "16px", height: "16px", fill: "#000" }}
              />
            </>
          ) : (
            <>
              <p className="desc_texts">Cancle</p>
              <img
                src="/assets/editicon.svg"
                alt="edit"
                style={{ width: "16px", height: "16px", fill: "#000" }}
              />
            </>
          )}
        </div> */}
      </div>

      <div className={styles.whiteContainer}>
        <form>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div className={contactStyles.formcontrol}>
              <label htmlFor="Full_name">Full Name</label>
              <input
                name="fullName"
                type="text"
                value={profileState.fullName}
                style={{ cursor: "auto" }}
                // onChange={handleProfileChange}
                id="Full_name"
                className="inputcontrol"
                placeholder="Name"
                contentEditable={false}
                readOnly={true}
              />
            </div>
            {/* <div className={contactStyles.formcontrol}>
              <label htmlFor="Company">Company</label>
              <input
                name="company"
                type="text"
                value={userData.company}
                disabled={!editable}
                style={{ cursor: !editable ? "not-allowed" : "auto" }}
                onChange={handleProfileChange}
                // value={newSubsData.sub_description}
                id="Company"
                className="inputcontrol"
                placeholder="Add Description"
              />
            </div> */}
            <div className={contactStyles.formcontrol}>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                value={profileState.email}
                style={{ cursor: "auto" }}
                // onChange={handleProfileChange}
                // value={newSubsData.sub_description}
                id="email"
                className="inputcontrol"
                placeholder="Email"
                contentEditable={false}
                readOnly={true}
              />
            </div>
            {/* <div className={contactStyles.formcontrol}>
              <label htmlFor="phone">Phone Number</label>
              <input
                name="phone"
                type="number"
                value={userData.phone}
                disabled={!editable}
                style={{ cursor: !editable ? "not-allowed" : "auto" }}
                 // onChange={handleProfileChange} 
                // value={newSubsData.sub_description}
                id="Company"
                className="inputcontrol"
                placeholder="Phone Number"
                onInput={(e) => {
                  if (e.target.value > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
              />
            </div> */}
            {/* <div className={contactStyles.formcontrol}>
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                value={userData.password}
                disabled={!editable}
                style={{ cursor: !editable ? "not-allowed" : "auto" }}
                onChange={handleProfileChange}
                // value={newSubsData.sub_description}
                id="password"
                className="inputcontrol"
                placeholder="password"
              />
            </div> */}
          </div>
          {/* <Link to={"/dashboard/manage-subscription"}> */}
          <button
            className={substyles.buttonType_1}
            style={{
              flex: "none",
              marginTop: "32px",
              marginLeft: "auto",
              cursor: loading ? "not-allowed" : "pointer",
              opacity: loading ? 0.5 : 1,
            }}
            type="submit"
            disabled={loading}
            onClick={handleSubscription}
          >
            {buttontext ? "Cancel Subscription" : "Get Premium"}
          </button>
          {/* </Link> */}
        </form>
      </div>
    </div>
  );
}

export default Profile;
