import RecommendedApp from "../../HomePage/RecommendedApp";
import SubscriprionCards from "../../common/commonComponents/SubscriptionCards";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";

import RightSideBar from "../../miscComponents/RightSideBar";
import CarouselComponent from "./CarouselComponent";
// import BarChartComponent from "@/components/common/commonComponents/chartcomponents/BarChartComponent";
import BarChartComponent from "../../common/commonComponents/chartcomponents/BarChartComponent";
// import DataTable from "@/components/common/commonComponents/Datatable/Datatable";
import DataTable from "../../common/commonComponents/Datatable/Datatable";
import { useAuth } from "../../../context/authContext";
import NoLoginText from "../../common/commonComponents/NoLoginTextCompoenent";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRecommendedSubscriptions,
  showNotifications,
  allSubscriptionAmountsGraph,
  currentSubscriptionList,
} from "../../../redux/features/subscriptionSlice";
import { Select } from "antd";
import { Link } from "react-router-dom";
import FeatureCard from "../../features/FeatureCard";
import DashbordFeatures from "../../commonComponents/UnsubscribedFeatures";

function DashboardComponent() {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [days, setDays] = useState(30);
  const {
    currentSubsList,
    recomendedsubscription,
    notifications,
    subscriptionAmountGraphData,
  } = useSelector((state) => ({
    ...state.subscription,
  }));
  const { isUserSubscribed } = useAuth();
  // console.log(isUserSubscribed, "isUserSubscribed in dashboard");

  useEffect(() => {
    dispatch(showNotifications());

    dispatch(GetRecommendedSubscriptions());

    dispatch(currentSubscriptionList());
  }, []);
  
  useEffect(() => {
    let data = { days };
    dispatch(allSubscriptionAmountsGraph(data));
  }, [days]);
  // console.log(recomendedsubscription, "notifications");
  const handleChange = (value) => {
    // console.log(value.value,"value"); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    setDays(value.value);
  };
  const createSubscription = async (priceId) => {
    const { subscriptionId, clientSecret } = await fetch(
      "/api/subscription/createsubscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
        }),
      }
    ).then((r) => r.json());
    console.log(clientSecret, "clientSecret");
    setSubscriptionData({ subscriptionId, clientSecret });
  };
  // console.log(subscriptionData, "subscriptionData");
  if (subscriptionData) {
    return <Navigate to="/subscribe" state={subscriptionData} />;
  }

  return (
    <>
      {
        // subscriptionData ? (
        currentSubsList && subscriptionAmountGraphData && (
          <div className={styles.dashBoardContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.mainheader}>
                <div
                  className={styles.sec_1}
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <h3
                    className="postloginHeading"
                    style={{ fontWeight: "500" }}
                  >
                    Current Subscription |{" "}
                    <span className="sq_div">{currentSubsList?.length}</span>{" "}
                    <span
                      className="underlineText"
                      style={{
                        color: "#777",
                        fontWeight: "400",
                        textDecoration: "none",
                      }}
                    >
                      active subscripitons
                    </span>
                  </h3>
                  <Link
                    to="/dashboard/subscription-list"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      className="underlineText"
                      style={{
                        color: "#030303",
                        textDecoration: "underline",
                        fontSize: "1.6rem",
                      }}
                    >
                      (view all)
                    </p>
                  </Link>
                </div>
                <div className={styles.sec_2}>
                  <Link to="/dashboard/add-new">
                    <p
                      className="text_sm"
                      style={{
                        color: "#777",
                        fontWeight: "500",
                        textDecoration: "none",
                      }}
                    >
                      + Add Subscription
                    </p>
                  </Link>
                </div>
              </div>
              <div className="componentWrapper">
                {currentSubsList.length > 0 ? (
                  <CarouselComponent currentSubsList={currentSubsList} />
                ) : (
                  <p className="text_sm">No Subscription is actiavted</p>
                )}
              </div>
              <div className="componentWrapper">
                <div className="flex items-center justify-between">
                  <h2 className="postloginHeading">
                    User Subscription Spending Graph
                  </h2>
                  <Select
                    className="select-class"
                    style={{ border: "none !important" }}
                    labelInValue
                    defaultValue={{
                      value: 30,
                      label: (
                        <p
                          className="text_very_sm"
                          style={{ marginBottom: "0px" }}
                        >
                          30 days
                        </p>
                      ),
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: 60,
                        label: (
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            60 days
                          </p>
                        ),
                      },
                      {
                        value: 30,
                        label: (
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            30 days
                          </p>
                        ),
                      },
                      {
                        value: 180,
                        label: (
                          <p
                            className="text_very_sm"
                            style={{ marginBottom: "0px" }}
                          >
                            6 Months
                          </p>
                        ),
                      },
                    ]}
                  />
                </div>

                <BarChartComponent
                  subscriptionAmountGraphData={subscriptionAmountGraphData}
                />
              </div>
              <div className="componentWrapper">
                <h2 className="postloginHeading">Recommended Apps</h2>
                <DataTable recomendedsubscription={recomendedsubscription} />
              </div>
              <Link
                className="underlinetext"
                to="/dashboard/subscription-list"
                style={{
                  color: "#030303",
                  fontWeight: "400",
                  textAlign: "right",
                }}
              >
                view all
              </Link>
              {/* <CarouselComponent /> */}
            </div>
            <div className={styles.sideContainer}>
              <h3
                className="postloginHeading"
                style={{ marginBottom: "8px", fontWeight: "500" }}
              >
                Overview
              </h3>
              <RightSideBar notifications={notifications} />
            </div>
          </div>
        )
        // )
        // : (
        //   <p onClick={() => createSubscription("price_1O1pl2SEQnSg1FudRdLAEJHa")}>
        //     You Dont Have Plans{" "}
        //   </p>
        // )
      }
    </>
  );
}

export default DashboardComponent;
