import React from "react";
import ParentHeroComponent from "../commonComponents/ParentHeroComponent";
import styles from "./about.module.css";
import commonStyles from "../commonComponents/common.module.css";

const HeroSection = () => {
  return (
    <ParentHeroComponent>
      <div className="pageWrapper">
        <div className={commonStyles.iconcontainer}>
          <img
            src="/assets/alladinicongray.svg"
            className="img"
            alt="alldin icon"
          />
        </div>
        <div className={styles.heroContainer}>
          <div className={styles.header} style={{ textAlign: "center" }}>
            <h1 className="header_1" style={{ marginBottom: "8px" }}>
              We Work Together
            </h1>
            <p className="desc_texts">
              At Alladdin, we believe in simplifying the way people access and
              manage their subscriptions. As a monthly subscription service, we
              offer users unparalleled access to the world's largest network of
              online subscription-based companies. Our platform serves as a
              centralized hub where users can effortlessly discover, subscribe
              to, and manage a wide variety of products and services. Our
              mission is to empower users to take control of their subscriptions
              by providing them with a convenient and intuitive platform. With
              Alladdin, users can say goodbye to the hassle of managing multiple
              subscriptions across various platforms. Instead, they can
              consolidate all their subscriptions in one place, making it easier
              than ever to stay organized and in control. Whether it's streaming
              services, software subscriptions, meal delivery plans, or fitness
              memberships, Alladdin has you covered. Our extensive network
              ensures that users have access to a diverse range of products and
              services tailored to their preferences and needs. At Alladdin, we
              are committed to providing our users with a seamless and enjoyable
              experience. Our platform is designed with user convenience and
              satisfaction in mind, offering intuitive navigation, personalized
              recommendations, and reliable customer support. Join Alladdin
              today and discover a world of convenience, simplicity, and choice
              in managing your subscriptions. Experience the freedom to explore
              and enjoy the best that subscription-based companies have to
              offer, all in one place.
            </p>
          </div>
          <div className={styles.imagecontainer}>
            <img
              className="img"
              src={"/assets/aboutusimage.png"}
              alt="about-us"
            />
          </div>
        </div>
      </div>
    </ParentHeroComponent>
  );
};

export default HeroSection;
