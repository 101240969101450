import React, { useEffect } from "react";
import styles from "./addnew.module.css";
import CardsComponent from "./CardsComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions } from "../../redux/features/subscriptionSlice";
import { currentSubscriptionList } from "../../redux/features/subscriptionSlice";
import { useLocation } from "react-router";

const AddNew = () => {
  const dispatch = useDispatch();
  const { allSubscriptions } = useSelector((state) => state.subscription);
  let { state } = useLocation();
  console.log(state, "state");

  useEffect(() => {
    dispatch(GetALLSubscriptions());
  }, []);
  
  console.log(allSubscriptions, "allSubscriptions");
 
  return (
    <div className={"w-full flex flex-col gap-2"}>
      <h2 className="postloginHeading">Add New</h2>
      <p className="text_sm" style={{ textAlign: "left" }}>
        Automatically merge your subscription through our APIs listed below
      </p>
      <CardsComponent allSubscriptions={allSubscriptions} state={state} />
    </div>
  );
};

export default AddNew;
