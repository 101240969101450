import React from "react";
import styles from "./explore.module.css";
import { Rating } from "@mui/material";
const TestimonialCard = ({ testimonial }) => {
  return (
    <div className={styles.card}>
      <p className="desc_texts">{testimonial.testimonial}</p>
      <div className={styles.cardFoorer}>
        <p
          className="normal_sm_text"
          style={{ color: "#424242", fontWeight: "700" }}
        >
          {testimonial.author}
        </p>
        <Rating
          name="half-rating-read"
          defaultValue={testimonial.stars}
          precision={0.5}
          readOnly
          size="large"
          sx={{ color: "#374957" }}
        />
      </div>
    </div>
  );
};

export default TestimonialCard;
