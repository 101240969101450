"use client";
import React, { useEffect, useState } from "react";
import styles from "./misc.module.css";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { Button, Dropdown, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userSpendingData } from "../../redux/features/subscriptionSlice";
import CalenderView from "../common/commonComponents/Calender";
import { Link } from "react-router-dom";

const RightSideBar = ({ notifications }) => {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const dispatch = useDispatch();
  const [spend_type, setSpendType] = useState("Projected");
  const [days, setDays] = useState(30);

  const { userSpendData } = useSelector((state) => ({
    ...state.subscription,
  }));

  const handleChange = (value) => {
    // console.log(value, "value");
    setSpendType(value.value);
  };

  const handleDaysChange = (value) => {
    // console.log(value.value, "value in days");
    setDays(value.value);
  };

  useEffect(() => {
    let data = { spend_type, days };
    dispatch(userSpendingData(data));
  }, [spend_type, days]);
  // console.log(userSpendData, "userSpendData");
  return (
    <div className={styles.mainContainer}>
      <div className={styles.upperContainer}>
        {/* <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <p className="text_very_sm">Projected Spending</p>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>

        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              30days
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown> */}
        <Select
          labelInValue
          defaultValue={{
            value: "Projected",
            label: <p className="text_very_sm">Projected Spending</p>,
          }}
          onChange={handleChange}
          options={[
            {
              value: "Past",
              label: <p className="text_very_sm">Past Spending</p>,
            },
            {
              value: "Projected",
              label: <p className="text_very_sm">Projected Spending</p>,
            },
          ]}
          className="select1"
        />

        <Select
          labelInValue
          defaultValue={{
            value: 30,
            label: (
              <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                30 days
              </p>
            ),
          }}
          onChange={handleDaysChange}
          options={[
            {
              value: 60,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  60 days
                </p>
              ),
            },
            {
              value: 30,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  30 days
                </p>
              ),
            },
          ]}
        />

        <div className={styles.amountdiv}>
          <p className="header_2">{`$ ${
            userSpendData?.[0]?.total_spending
              ? userSpendData?.[0]?.total_spending
              : 0
          }`}</p>
          <Link
            className="underlinetext"
            to="/dashboard/transactions"
            style={{ color: "#030303", fontWeight: "400" }}
          >
            See all Transactions
          </Link>
        </div>
      </div>

      <div className={styles.middlecontainer}>
        <p className="text_very_sm">Calender</p>
        <CalenderView inlineCalendar={"inlineCalendar"} />
      </div>
      <div className={styles.lowerContainer}>
        <div className="flex items-center justify-between w-full">
          <p className="text_very_sm">Notification</p>
          <Link to="/dashboard/notifications">
            <p
              className="underlinetext"
              style={{ color: "#777", fontWeight: "500" }}
            >
              {" "}
              See all
            </p>
          </Link>
        </div>
        <div
          className={styles.notificationcontainer}
          style={{ marginTop: "5px", marginBottom: "10px" }}
        >
          <p className="text_very_sm" style={{ fontWeight: "400" }}>
            {notifications?.length} New Notifications
          </p>
          <img
            src={"/assets/notifydot.svg"}
            width={8}
            height={8}
            alt="notify"
          />
        </div>

        <div className={styles.notificationBox}>
          {notifications?.map((item, index) => (
            <div className={styles.notificationItemcontainer} key={index}>
              <img
                src={item.img_url || "/assets/netflixicon.png"}
                width={25}
                height={25}
                alt="netflix"
              />
              <p
                className="underlinetext"
                style={{ color: "#030303", textDecoration: "none" }}
              >
                Your {item?.title} {item?.details} subscription is expiring on {item?.end_date}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
