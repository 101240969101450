import React from "react";

const TermsOfService = () => {
  return (
    <div>
      <h1 className="header_2" style={{ marginBottom: "32px" }}>
        Terms of Service{" "}
      </h1>
      <p className="desc_texts">
        Welcome to our platform! By using our services, you agree to abide by
        our terms and conditions. Please read them carefully. User
        Responsibility: You are responsible for your actions on our platform.
        Privacy: We value your privacy; your data is protected as per our
        Privacy Policy. Content: Respect copyrights and intellectual property
        rights when sharing content. Prohibited Activities: No spam, harassment,
        or illegal activities allowed. Liability: We're not liable for any
        damages or losses incurred. Termination: We reserve the right to
        terminate accounts for violations. By using our platform, you agree to
        these terms. If you disagree, please do not use our services. Thank you
        for choosing us! Please note that this is a general sample, and you
        should customize your terms of service to match your specific platform
        and services. It's also recommended to consult with a legal professional
        to ensure that your terms of service are legally sound and compliant
        with applicable laws.
      </p>
    </div>
  );
};

export default TermsOfService;
