import React from "react";
import styles from "./common.module.css";


const ParentHeroComponent = ({ children }) => {
  return (
    <div
      className="wholeWidthContainer"
      style={{ position: "relative", background: "#D0EE11" }}
    >
      {children}
    </div>
  );
};

export default ParentHeroComponent;
