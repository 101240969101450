import React from "react";
import styles from "./about.module.css";

const AboutSection = () => {
  return (
    <div className="pageWrapper">
      <div className={styles.aboutcontainer}>
        <h2 className="header_2" style={{ fontWeight: "700" }}>
          Who are we?
        </h2>
        <p className="desc_texts">
          We are a team dedicated to making your subscription management a
          breeze. At Alladdin, we strive to simplify the process of handling all
          your subscriptions, ensuring they are organized and hassle-free. With
          our platform, you can say goodbye to the stress of managing multiple
          subscriptions and enjoy peace of mind knowing that everything is taken
          care of efficiently. Our mission is to provide you with a seamless and
          intuitive experience, allowing you to focus on what matters most while
          we handle the rest. Trust Alladdin to revolutionize the way you manage
          your subscriptions and discover a new level of convenience and
          organization.
        </p>
        <div className={styles.imagecontainer}>
          <img
            className="img"
            src={"/assets/aboutusimage.png"}
            alt="about-us"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
