import React from "react";
import { useNavigate } from "react-router";

function FailedPage() {
  const navigate = useNavigate();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
  };

  const handleRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "48px", marginTop: "20px" }}>Payment Failed!!!</h1>
      <div
        className="tick-animation"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/assets/cancel.gif"}
          alt="Red Tick"
          style={{ width: "50%", height: "80%" }}
        />
      </div>

      <h1 style={{ fontSize: "24px", marginTop: "20px" }}>
        Something Went Wrong.
      </h1>
      <p style={{ fontSize: "18px", marginTop: "20px" }}>
        Please Try again after some time.
      </p>

      <button
        onClick={handleRedirect}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#d0ee11",
          color: "#333",
          borderRadius: "5px",
          cursor: "pointer",
          border: "none",
        }}
      >
        Go to Dashboard
      </button>
    </div>
  );
}

export default FailedPage;
