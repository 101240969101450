import React from "react";
import styles from "./notification.module.css";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import {
  showNotifications,
  removeNotification,
} from "../../../redux/features/subscriptionSlice";

const options = ["Delete"];
const ITEM_HEIGHT = 48;

const NotificationBody = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setselectedIndex] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setselectedIndex(id);
  };
  // console.log(selectedIndex, "selectedIndex");
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // console.log(selectedIndex, "selectedIndex");
    dispatch(removeNotification({ notification_id: selectedIndex })).then(
      (data) => {
        if (data?.payload?.code == 200) {
          dispatch(showNotifications());
        }
      }
    );
    handleClose();
  };
  return (
    <div className={styles.notificationwrapper}>
      <h5 className="text_very_sm">Today</h5>
      {notifications?.map((item, index) => (
        <div className={styles.notifycontainer} key={index}>
          <div className={styles.left}>
            <img
              src="/assets/purpleicon.svg"
              alt=""
              style={{ width: "11px", height: "11px" }}
            />
            <img
              src={item?.img_url || "images/purpleicon.svg"}
              alt=""
              style={{ width: "26px", height: "26px" }}
            />
            <div className={styles.textContainer}>
              <p className="text_very_sm" style={{ opacity: "0.6" }}>
                {item?.title}
              </p>
              <p
                className="text_very_sm"
                style={{ opacity: "0.6", fontWeight: "400" }}
              >
                Your {item?.title} {item?.details} subscription is expiring on{" "}
                {item?.end_date}
              </p>
            </div>
          </div>
          <div className={styles.right}>
            <p
              className="underlinetext"
              style={{
                color: "#030303",
                fontWeight: "500",
                textDecoration: "none",
              }}
            >
              {item?.created_at}
            </p>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => handleClick(event, item?.id)}
                style={{ width: "16px", height: "16px" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                    // height: "16px",
                  },
                }}
              >
                {item?.id && (
                  <MenuItem key={item?.id} onClick={handleDelete}>
                    {"Delete"}
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationBody;

{
  /* <img
              onClick={() => {}}
              src="/assets/doticon.svg"
              alt=""
              style={{ width: "16px", height: "16px", cursor: "pointer" }}
            /> */
}
// <div className={styles.notificationItemcontainer} key={index}>
//   <img
//     src={item.img_url || "/assets/netflixicon.png"}
//     width={25}
//     height={25}
//     alt="netflix"
//   />
//   <p
//     className="underlinetext"
//     style={{ color: "#030303", textDecoration: "none" }}
//   >
//     Your {item?.sub_name} subscription is expiring on 07 July
//   </p>
// </div>;
