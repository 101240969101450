import React from "react";
import styles from "./contact.module.css";
import ContactForm from "./ContactForm";

const FormSection = () => {
  return (
    <div className="wholeWidthContainer" style={{ background: "#F5F5F5" }}>
      <div className="pageWrapper">
        <div className={styles.formContainer}>
          <h2
            className="header_2"
            style={{ color: "#030303", fontWeight: "700" }}
          >
            Contact Form
          </h2>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default FormSection;
